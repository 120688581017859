import React from 'react';
import {Badge} from 'primereact/badge';

interface Props {
  onClick: () => void;
  label: string;
  invalidMessagesCount: number;
}

export const BottomTabMenuItem = ({onClick, label, invalidMessagesCount}: Props) => {
  return (
    <a className="p-menuitem-link dcm-bottom-tab-menu-item" onClick={onClick}>
      <div className="p-menuitem-text p-mx-auto p-d-flex">
        <div className="p-my-auto">{label}</div>
        {!!invalidMessagesCount && <Badge value={invalidMessagesCount} className="p-ml-1" severity="danger" />}
      </div>
    </a>
  );
};
