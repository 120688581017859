import React from 'react';
import {AuthService, ToastService, UsersService} from 'two-app-ui';
import JobsService from '../services/JobsService';
import AppointmentsService from '../services/AppointmentsService';
import JobDocumentsService from '../services/JobDocumentsService';
import ProductDefinitionsService from '../services/ProductDefinitionsService';
import TleService from '../services/TleService';
import CompaniesService from '../services/CompaniesService';
import ContactsService from '../services/ContactsService';

type Made2FitAppContextProps = {
  authService: AuthService;
  usersService: UsersService;
  toastService: ToastService;
  jobsService: JobsService;
  appointmentsService: AppointmentsService;
  jobDocumentsService: JobDocumentsService;
  productDefinitionsService: ProductDefinitionsService;
  tleService: TleService;
  companiesService: CompaniesService;
  contactsService: ContactsService;
};

export const Made2FitAppContext = React.createContext<Partial<Made2FitAppContextProps>>({});
export default Made2FitAppContext;
