import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {TimeLineEvent} from 'two-core';

class TleService extends ApiService {
  endpoint: string;

  constructor(aus: AuthService) {
    super(aus);
    this.endpoint = config().endpoints?.tles as string;
  }

  async createTle(data: TimeLineEvent): Promise<TimeLineEvent> {
    return this.post(`${this.endpoint}`, data)
      .then(data => {
        return Promise.resolve(data as TimeLineEvent);
      })
      .catch(() => {
        return Promise.reject();
      });
  }
}

export default TleService;
