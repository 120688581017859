import React from 'react';
import {JobStage} from 'two-core';
import {Button} from 'primereact/button';
import {faFile, faFileLock, faFilePen} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

interface Props {
  jobStage: JobStage;
  onOriginalClick?: () => void;
  onDcmClick?: () => void;
  onOrderClick?: () => void;
}

export const DocumentsContent = ({jobStage, onOriginalClick, onDcmClick, onOrderClick}: Props) => {
  const dcmReadOnly = !(jobStage === 'Measure Started' || jobStage === 'Measure Finished');
  if (onOriginalClick === undefined && onDcmClick === undefined && onOrderClick === undefined) {
    return <></>;
  }
  return (
    <div className="p-mb-4">
      {onOriginalClick !== undefined && (
        <div className="p-field p-grid p-mb-3">
          <label className="p-col-2">documents</label>
          <div className="p-col-3 p-align-center">
            <Button
              label="Original Request"
              icon={<FontAwesomeIcon icon={faFileLock} className="p-mr-2" />}
              onClick={onOriginalClick}
              className="p-button-link p-button-info p-pl-0 p-pt-2 p-pr-2 p-pb-2 job-link"
            />
          </div>
        </div>
      )}
      {onDcmClick !== undefined && (
        <div className="p-field p-grid p-mb-3">
          <label className="p-col-2"></label>
          <div className="p-col-3">
            <Button
              label="DCM"
              icon={<FontAwesomeIcon icon={dcmReadOnly ? faFileLock : faFilePen} className="p-mr-2" />}
              onClick={onDcmClick}
              className="p-button-link p-button-info p-pl-0 p-pt-2 p-pr-2 p-pb-2 job-link"
            />
          </div>
        </div>
      )}
      {onOrderClick !== undefined && (
        <div className="p-field p-grid p-mb-3">
          <label className="p-col-2"></label>
          <div className="p-col-3">
            <Button
              label="Order"
              icon={<FontAwesomeIcon icon={faFile} className="p-mr-2" />}
              onClick={onOrderClick}
              className="p-button-link p-button-info p-pl-0 p-pt-2 p-pr-2 p-pb-2 job-link"
            />
          </div>
        </div>
      )}
    </div>
  );
};
