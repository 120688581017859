import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import React from 'react';
import {Subscription} from 'rxjs';
import {PaCompany} from 'two-core';
import {MessageService, TwoMessage} from 'two-app-ui';
import config from '../../../config/config';

interface Props {
  companies: PaCompany[];
}

interface State {
  selectedCompanyId: string;
  title: string;
  showCompanyDropdown: boolean;
}

class RightPart extends React.Component<Props, State> {
  subscription: Subscription = new Subscription();
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedCompanyId: '',
      title: '',
      showCompanyDropdown: false,
    };
    this.onCompanyChange = this.onCompanyChange.bind(this);
    this.companyIemTemplate = this.companyIemTemplate.bind(this);
  }

  async componentDidMount() {
    this.subscription = MessageService.getMessage().subscribe(async message => {
      if (message === config().messages.schedulerViewed) {
        this.setState({
          showCompanyDropdown: true,
          title: '',
        });
      } else if (message === config().messages.topSelectionDataLoaded) {
        this.setState({
          selectedCompanyId: localStorage.getItem(config().ls_keys.current_company) ?? '',
        });
      } else {
        const castedMessage = message as TwoMessage;
        if (castedMessage?.name === config().messages.jobViewed) {
          this.setState({
            showCompanyDropdown: false,
            title: '',
          });
        }
      }
    });
    this.setState({
      selectedCompanyId: localStorage.getItem(config().ls_keys.current_company) ?? '',
    });
  }

  async onCompanyChange(option: DropdownChangeParams) {
    const value = option.value as string;
    this.setState({selectedCompanyId: value});
    localStorage.setItem(config().ls_keys.current_company, value);
    MessageService.sendMessage(config().messages.topSelectionChanged);
  }

  companyIemTemplate(company: PaCompany) {
    if (company?.name) {
      if (company.trading_as) {
        return (
          <span className="p-text-wrap">
            {company.name} [ {company.trading_as} ]
          </span>
        );
      }
      return <span>{company.name}</span>;
    }
    return 'empty';
  }

  render() {
    const {selectedCompanyId, showCompanyDropdown, title} = this.state;
    const {companies} = this.props;

    let content = <></>;
    if (showCompanyDropdown && companies.length > 0) {
      content = (
        <Dropdown
          id="right_toolbar"
          className="p-inputtext-sm p-ml-1"
          value={selectedCompanyId}
          options={companies}
          optionValue={'id'}
          optionLabel={'name'}
          onChange={this.onCompanyChange}
          itemTemplate={this.companyIemTemplate}
          valueTemplate={this.companyIemTemplate}
          filter
          filterBy="name,trading_as"
          panelClassName="right-toolbar-panel"
        />
      );
    } else {
      content = <>{title}</>;
    }
    return <div className="app-toolbar-part p-text-right">{content}</div>;
  }
}

export default RightPart;
