import React from 'react';
import {Job} from 'two-core';
import {Button} from 'primereact/button';

interface Props {
  job: Job;
}

export const ContactsContent = (props: Props) => {
  const job = props.job;
  const endCustomer = job.end_customer;
  const owner = job.owner!;
  const requestedBy = job.requested_by;
  const otherContacts = job.other_contacts;

  const onPhoneClick = (phone: string) => {
    window.open(`tel:${phone}`, '_self');
  };

  return (
    <div className="p-mb-4">
      <div className="p-field p-grid p-mb-1">
        <label className="p-col-2">
          <small>end customer</small>
        </label>
        <div className="p-col-7">{`${endCustomer.first_name ?? ''} ${endCustomer.last_name ?? ''}`}</div>
        <div className="p-col-3">
          <Button
            className="p-button-link p-button-info p-p-0 p-ml-2 p-mb-1 job-link"
            label={endCustomer.phone}
            onClick={() => onPhoneClick(endCustomer.phone)}
          />
        </div>
      </div>
      <div className="p-field p-grid p-mb-1">
        <label className="p-col-2">
          <small>dealer</small>
        </label>
        <div className="p-col-4">
          <div className="p-mr-1 p-d-inline-flex">{owner.name}</div>
          {owner.trading_as && <div className="p-d-inline-flex">({owner.trading_as})</div>}
        </div>
        <div className="p-col-3">{requestedBy.label}</div>
        <div className="p-col-3">
          {owner.phone_number && (
            <Button
              className="p-button-link p-button-info p-p-0 p-ml-2 p-mb-1 job-link"
              label={owner.phone_number}
              onClick={() => onPhoneClick(owner.phone_number!)}
            />
          )}
        </div>
      </div>
      {otherContacts?.map((contact, index) => (
        <div className="p-field p-grid p-mb-1" key={index}>
          <label className="p-col-2">{index === 0 && <small>other</small>}</label>
          <div className="p-col-7">{`${contact.first_name ?? ''} ${contact.last_name ?? ''}`}</div>
          <div className="p-col-3">
            {contact.phone && (
              <Button
                className="p-button-link p-button-info p-p-0 p-ml-2 p-mb-1 job-link"
                label={contact.phone}
                onClick={() => onPhoneClick(contact.phone)}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
