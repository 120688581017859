import React from 'react';
import {DcmView, ValidationMessage} from '../Dcm';
import {TabMenu, TabMenuTabChangeParams} from 'primereact/tabmenu';
import {MenuItem} from 'primereact/menuitem';
import {BottomTabMenuItem} from './BottomTabMenuItem';

interface Props {
  activeView: DcmView;
  onViewChange: (newView: DcmView) => void;
  validationMessages: ValidationMessage[];
  readOnly: boolean;
  disabled?: boolean;
}

interface TabMenuItem extends MenuItem {
  key: DcmView;
}
export const BottomTabMenu = ({activeView, onViewChange, validationMessages, readOnly, disabled}: Props) => {
  const getTemplate = (item: TabMenuItem) => (
    <BottomTabMenuItem
      onClick={() => onViewChange(item.key)}
      label={item.label!}
      invalidMessagesCount={validationMessages.filter(message => message.view === item.key).length}
    />
  );

  const itemWidth = readOnly ? '50%' : '33.3%';

  const items: TabMenuItem[] = [
    {
      label: 'Measure',
      style: {width: itemWidth},
      key: 'measure',
      template: getTemplate,
      disabled: disabled,
    },
    {
      label: 'Info',
      style: {width: itemWidth},
      key: 'info',
      template: getTemplate,
      disabled: disabled,
    },
    {
      label: 'Release',
      style: {width: itemWidth},
      key: 'release',
      template: getTemplate,
      disabled: disabled,
    },
  ];

  const activeIndex = items.findIndex(item => item.key === activeView);

  const onTabChange = (e: TabMenuTabChangeParams) => {
    onViewChange(e.value.key);
  };

  return (
    <TabMenu
      model={items}
      className={'dcm-bottom-tab-menu w-100 p-shadow-2'}
      activeIndex={activeIndex}
      onTabChange={onTabChange}
    />
  );
};
