import React from 'react';
import {ScrollPanel} from 'primereact/scrollpanel';
import {getTwoDateFormat, MessageService, ToastService, TwoSpeedDial, TwoSpeedDialItem, UsersService} from 'two-app-ui';
import {config} from '../../config/config';
import {ApiListResponse, Appointment, Job, JobAggregate, QueryParameter} from 'two-core';
import {ProgressSpinner} from 'primereact/progressspinner';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import Made2FitAppContext from '../../context/Made2FitAppContext';
import JobsService from '../../services/JobsService';
import {Toast} from 'primereact/toast';
import {Accordion, AccordionTab, AccordionEventParams} from 'primereact/accordion';
import {JobCard} from './JobCard';
import {DateTime} from 'luxon';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMagnifyingGlass} from '@fortawesome/pro-thin-svg-icons';
import './Scheduler.scss';
import {MenuItem} from 'primereact/menuitem';
import {faArrowsMaximize, faArrowsMinimize, faArrowsRotate} from '@fortawesome/pro-regular-svg-icons';
import {Subscription} from 'rxjs';

interface State {
  allJobs: Job[];
  newJobs: Job[];
  scheduledDays: DayJobs[];
  underReviewJobs: Job[];
  loading: boolean;
  searchValue: string;
  openTabs: boolean[];
}

class Scheduler extends React.Component<RouteComponentProps, State> {
  static contextType = Made2FitAppContext;
  subscription: Subscription = new Subscription();

  jobsService?: JobsService;
  toastService?: ToastService;
  usersService?: UsersService;
  toast: React.RefObject<Toast>;
  typingTimer?: NodeJS.Timeout;

  constructor(props: RouteComponentProps) {
    super(props);
    this.state = {
      allJobs: [],
      newJobs: [],
      scheduledDays: [],
      underReviewJobs: [],
      loading: false,
      searchValue: localStorage.getItem(config().ls_keys.jobs_search_value) ?? '',
      openTabs: [],
    };

    this.loadJobs = this.loadJobs.bind(this);
    this.filterJobs = this.filterJobs.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onCollapseAll = this.onCollapseAll.bind(this);
    this.onExpandALl = this.onExpandALl.bind(this);
    this.onTabClose = this.onTabClose.bind(this);
    this.onTabOpen = this.onTabOpen.bind(this);

    this.toast = React.createRef();
  }

  async componentDidMount() {
    this.toastService = this.context.toastService;
    this.jobsService = this.context.jobsService;
    this.usersService = this.context.usersService;

    this.subscription = MessageService.getMessage().subscribe(async message => {
      if (message === config().messages.topSelectionChanged || message === config().messages.topSelectionDataLoaded) {
        this.loadJobs();
      }
    });

    // at login, the app reach this point before the company is set, however
    // this line cannot be removed since its needed for when you come back from a job
    // so we set this condition for it, and we dont call it 'hacky' but 'defensive'
    if (localStorage.getItem(config().ls_keys.current_company)) {
      this.loadJobs();
    }

    MessageService.sendMessage(config().messages.schedulerViewed);
  }

  async loadJobs() {
    const openTabsString = localStorage.getItem(config().ls_keys.schedule_open_tabs);
    let savedOpenTabs: boolean[] = [];
    if (openTabsString) {
      savedOpenTabs = JSON.parse(openTabsString);
    }
    this.setState({loading: true, openTabs: savedOpenTabs}, () => {
      const filters: string[] = [];
      const orderBys = [JSON.stringify({field: 'updated_at', direction: 'DESC'})];
      const aggregate: JobAggregate[] = ['appointments', 'owner'];
      const params: QueryParameter = {
        filters: filters,
        orderBys: orderBys,
        aggregate: aggregate,
      };

      this.jobsService
        ?.getJobs(params)
        .then((data: ApiListResponse) => {
          this.setState({allJobs: (data.records as Job[]) ?? []}, () => this.filterJobs(false));
        })
        .catch(error => {
          this.toastService?.showError(this.toast, 'Records load failed');
          this.setState({loading: false});
          console.log(error);
        });
    });
  }

  filterJobs(newFilter: boolean) {
    const {searchValue, allJobs, openTabs} = this.state;
    let visibleJobs = [...allJobs];
    if (searchValue && searchValue.length > 0) {
      const searchLow = searchValue.toLowerCase();
      visibleJobs = allJobs.filter(job => {
        const inTitle = job.title.toLowerCase().includes(searchLow);
        const inAddress =
          job.address.street?.toLowerCase().includes(searchLow) ||
          job.address.suburb?.toLowerCase().includes(searchLow) ||
          job.address.state?.toLowerCase().includes(searchLow) ||
          job.address.phoneNumber?.toLowerCase().includes(searchLow) ||
          job.address.postCode?.toLowerCase().includes(searchLow) ||
          job.address.country?.toLowerCase().includes(searchLow);
        const inOwner =
          job.owner?.account_number?.toLowerCase().includes(searchLow) ||
          job.owner?.accounts_emails?.toLowerCase().includes(searchLow) ||
          job.owner?.name?.toLowerCase().includes(searchLow) ||
          job.owner?.trading_as?.toLowerCase().includes(searchLow);
        const inEndCustomer =
          job.end_customer.phone.toLowerCase().includes(searchLow) ||
          job.end_customer.email?.toLowerCase().includes(searchLow) ||
          job.end_customer.first_name.toLowerCase().includes(searchLow) ||
          job.end_customer.last_name.toLowerCase().includes(searchLow);
        const inDealerContact = job.created_by?.label?.toLowerCase().includes(searchLow);
        const inAppointment = job.appointments?.find(appointment => {
          return (
            appointment.title.toLowerCase().includes(searchLow) || appointment.note?.toLowerCase().includes(searchLow)
          );
        });
        return inTitle || inAddress || inOwner || inEndCustomer || inDealerContact || inAppointment;
      });
    }
    const newJobs: Job[] = [];
    const scheduledDays: DayJobs[] = [];
    const underReviewJobs: Job[] = [];
    for (const job of visibleJobs) {
      if (job.stage === 'New' || job.stage === 'Assigned') {
        newJobs.push(job);
      } else if (
        job.stage === 'Measure Booked' ||
        job.stage === 'Measure Started' ||
        job.stage === 'Measure Finished'
      ) {
        const appointment: Appointment = job.appointments!.find(
          appointment =>
            appointment.stage === 'Booked' || appointment.stage === 'Started' || appointment.stage === 'Finished'
        )!;
        const newDate = new Date(DateTime.fromISO(appointment.start_plan!.toString()).toFormat('yyyy-MM-dd'));
        let day: DayJobs | undefined;
        if (!scheduledDays.find(day => day.date.getTime() === newDate.getTime())) {
          const newDay: DayJobs = {
            date: newDate,
            dateString: DateTime.fromJSDate(newDate).toFormat(
              getTwoDateFormat(this.usersService?.settings?.date_format, 'shortDate')
            ),
            jobs: [],
          };
          scheduledDays.push(newDay);
          day = newDay;
        } else {
          day = scheduledDays.find(day => day.date.getTime() === newDate.getTime());
        }
        if (day) {
          day.jobs.push(job);
        }
      } else if (job.stage === 'Measure Review') {
        underReviewJobs.push(job);
      }
    }

    const newTabsCount = newJobs && newJobs.length > 0 ? 1 : 0;
    const dayTabsCount = scheduledDays ? scheduledDays.length : 0;
    const underReviewTabsCount = underReviewJobs && underReviewJobs.length > 0 ? 1 : 0;
    const allTabsCount = newTabsCount + dayTabsCount + underReviewTabsCount;
    let newOpenTabs: boolean[] = [];
    if (newFilter) {
      newOpenTabs = new Array(allTabsCount).fill(true);
    } else if (!openTabs || openTabs.length === 0 || openTabs.length !== allTabsCount) {
      if (newTabsCount > 0) {
        newOpenTabs.push(false);
      }
      if (dayTabsCount > 0) {
        newOpenTabs.push(...new Array(dayTabsCount).fill(true));
      }
      if (underReviewTabsCount > 0) {
        newOpenTabs.push(false);
      }
    } else {
      newOpenTabs = [...openTabs];
    }

    localStorage.setItem(config().ls_keys.schedule_open_tabs, JSON.stringify(newOpenTabs));

    this.setState({
      newJobs: newJobs,
      scheduledDays: scheduledDays,
      underReviewJobs: underReviewJobs,
      openTabs: newOpenTabs,
      loading: false,
    });
  }

  extractDate(fromDate: Date): Date {
    return new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDay());
  }

  async onSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    await this.setState({
      searchValue: event.target.value,
    });
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
    this.typingTimer = setTimeout(() => {
      localStorage.setItem(config().ls_keys.jobs_search_value, this.state.searchValue);
      this.filterJobs(true);
    }, config().stopTypingDetection);
  }

  onTabClose(e: AccordionEventParams) {
    const newOpenTabs = [...this.state.openTabs];
    newOpenTabs[e.index] = false;
    localStorage.setItem(config().ls_keys.schedule_open_tabs, JSON.stringify(newOpenTabs));
    this.setState({openTabs: newOpenTabs});
  }

  onTabOpen(e: AccordionEventParams) {
    const newOpenTabs = [...this.state.openTabs];
    newOpenTabs[e.index] = true;
    localStorage.setItem(config().ls_keys.schedule_open_tabs, JSON.stringify(newOpenTabs));
    this.setState({openTabs: newOpenTabs});
  }

  onCollapseAll() {
    const newOpenTabs = [...this.state.openTabs];
    newOpenTabs.fill(false);
    localStorage.setItem(config().ls_keys.schedule_open_tabs, JSON.stringify(newOpenTabs));
    this.setState({openTabs: newOpenTabs});
  }

  onExpandALl() {
    const newOpenTabs = [...this.state.openTabs];
    newOpenTabs.fill(true);
    localStorage.setItem(config().ls_keys.schedule_open_tabs, JSON.stringify(newOpenTabs));
    this.setState({openTabs: newOpenTabs});
  }

  render() {
    const {loading, scheduledDays, newJobs, underReviewJobs, searchValue, openTabs} = this.state;

    if (loading) {
      return (
        <div className="p-d-flex p-ai-center w-100 h-100">
          <ProgressSpinner />
        </div>
      );
    }

    const accordionTabs = [];
    if (newJobs.length) {
      accordionTabs.push(
        <AccordionTab header={`New Jobs${openTabs[0] ? '' : ` [${newJobs.length}]`}`} className="day-content">
          {newJobs.map(job => (
            <JobCard job={job} key={job.id} />
          ))}
        </AccordionTab>
      );
    }
    if (scheduledDays.length) {
      for (const day of scheduledDays) {
        const header = (
          <div className="p-d-flex w-100">
            <div>
              {`${day.dateString} [${DateTime.fromJSDate(day.date).toFormat(getTwoDateFormat(this.usersService?.settings?.date_format, 'weekday'))}]`}
            </div>
          </div>
        );
        accordionTabs.push(
          <AccordionTab headerTemplate={header} className="day-content">
            {day.jobs.map(job => (
              <JobCard job={job} key={job.id} />
            ))}
          </AccordionTab>
        );
      }
    }
    if (underReviewJobs.length) {
      accordionTabs.push(
        <AccordionTab header={'Under Review'} className="day-content">
          {underReviewJobs.map(job => (
            <JobCard job={job} key={job.id} />
          ))}
        </AccordionTab>
      );
    }

    const speedDialItems: MenuItem[] = [];
    if (openTabs.some(value => !value)) {
      speedDialItems.push({
        template: <TwoSpeedDialItem icon={faArrowsMaximize} onClick={this.onExpandALl} label="Expand" />,
      });
    } else {
      speedDialItems.push({
        template: <TwoSpeedDialItem icon={faArrowsMinimize} label="Collapse" onClick={this.onCollapseAll} />,
      });
    }
    speedDialItems.push({
      template: <TwoSpeedDialItem icon={faArrowsRotate} onClick={this.loadJobs} label="Refresh" />,
    });

    const activeIndexes: number[] = [];
    openTabs.forEach((value, index) => {
      if (value) {
        activeIndexes.push(index);
      }
    });

    return (
      <>
        <div id="jobs_page" className="app-page">
          <div className="jobs-content p-m-2">
            <div className="jobs-search p-inputgroup">
              <InputText
                name="searchValue"
                className="p-inputtext-sm"
                placeholder="Search"
                value={searchValue}
                onChange={this.onSearchChange}
              />
              <Button className="p-button-sm" icon={<FontAwesomeIcon icon={faMagnifyingGlass} />} />
            </div>
            <ScrollPanel className="p-mt-1 jobs-scroll-panel">
              <Accordion
                id="days-accordion"
                multiple
                className="w-100"
                activeIndex={activeIndexes}
                onTabClose={this.onTabClose}
                onTabOpen={this.onTabOpen}
              >
                {accordionTabs}
              </Accordion>
            </ScrollPanel>
          </div>
        </div>
        <TwoSpeedDial model={speedDialItems} />
        <Toast ref={this.toast} />
      </>
    );
  }
}

export default withRouter(Scheduler);

export interface DayJobs {
  date: Date;
  dateString: string;
  jobs: Job[];
}
