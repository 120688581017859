import {AppointmentStage, AppointmentType} from 'two-core';

export const appointmentTypes: AppointmentType[] = ['Check Measure', 'Installation', 'Consultation', 'Service Call'];
export const appointmentStages: AppointmentStage[] = [
  'Planned',
  'Invited',
  'Booked',
  'Started',
  'Completed',
  'Cancelled',
];
