import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, ApiResponse, Appointment, AppointmentPatch, QueryParameter} from 'two-core';

export default class AppointmentsService extends ApiService {
  constructor(aus: AuthService) {
    super(aus);
  }

  async createAppointment(data: AppointmentPatch): Promise<Appointment> {
    const companyId = localStorage.getItem(config().ls_keys.current_company)!;
    return this.post(config(companyId).endpoints.appointments, data)
      .then(data => {
        return Promise.resolve(data as Appointment);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  async updateAppointment(id: number, data: AppointmentPatch): Promise<Appointment> {
    const companyId = localStorage.getItem(config().ls_keys.current_company)!;
    return this.patch(config(companyId).endpoints.appointments + '/' + id, data)
      .then(data => {
        return Promise.resolve(data as Appointment);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  async getAppointments(params: QueryParameter): Promise<ApiListResponse> {
    const companyId = localStorage.getItem(config().ls_keys.current_company)!;
    return this.get(config(companyId).endpoints.appointments, params)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }
}
