import React from 'react';
import {Toolbar, ToolbarTemplateType} from 'primereact/toolbar';
import LeftToolbar from './LeftPart';
import CenterPart from './CenterPart';
import './AppToolbar.scss';

interface Props {
  right: ToolbarTemplateType;
}

export const AppToolbar = ({right}: Props) => {
  return (
    <div id="app_toolbar" className="p-shadow-2 p-d-flex p-flex-column p-py-1">
      <Toolbar
        className="p-p-2"
        left={
          <div className="p-d-flex p-jc-between p-ai-center w-100">
            <LeftToolbar />
            <CenterPart />
            {right}
          </div>
        }
      />
    </div>
  );
};
