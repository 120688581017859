import React from 'react';
import {ScrollPanel} from 'primereact/scrollpanel';
import {MessageService, ToastService, TwoSpeedDial, TwoSpeedDialItem} from 'two-app-ui';
import {
  ApiListResponse,
  CheckMeasureOriginalContent,
  ConsultOriginalContent,
  DcmContent,
  Field,
  InstallationInfo,
  JobDocument,
  JobDocumentAggregate,
  JobDocumentPatch,
  JobDocumentStage,
  JobPatch,
  OrderItem,
  OriginalContent,
  PaProductDefinition,
  QueryParameter,
  TimeLineEvent,
  TleContentJobDocEvent,
} from 'two-core';
import {ProgressSpinner} from 'primereact/progressspinner';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import Made2FitAppContext from '../../../context/Made2FitAppContext';
import {Toast} from 'primereact/toast';
import JobDocumentsService from '../../../services/JobDocumentsService';
import './Dcm.scss';
import ProductDefinitionsService from '../../../services/ProductDefinitionsService';
import {MeasureView} from './View/MeasureView';
import {InfoView} from './View/InfoView';
import {ReleaseView} from './View/ReleaseView/ReleaseView';
import {BottomTabMenu} from './BottomTabMenu/BottomTabMenu';
import {MenuItem} from 'primereact/menuitem';
import {
  faArrowsMaximize,
  faArrowsMinimize,
  faCheck,
  faEye,
  faEyeSlash,
  faFloppyDisk,
  faFloppyDiskCircleArrowRight,
} from '@fortawesome/pro-regular-svg-icons';
import {config} from '../../../config/config';
import JobsService from '../../../services/JobsService';
import TleService from '../../../services/TleService';

export type DcmView = 'measure' | 'info' | 'release';

export interface ValidationMessage {
  view: DcmView;
  message: string;
}

interface Props {
  jobId: string;
  jobDocumentId: string;
  tab?: string;
}

interface State {
  loading?: boolean;
  jobDocumentSaving?: boolean;
  jobDocumentReleasing?: boolean;
  dcm?: JobDocument;
  original?: JobDocument;
  dcmPatch: JobDocumentPatch;
  jobPatch: JobPatch;
  dcmProductDefinitions: PaProductDefinition[];
  originalProductDefinitions: PaProductDefinition[];
  clonedItems: OrderItem[];
  invalidItemMessagesMap?: Map<number, string[]>;
  collapsedItemIndexes: Set<number>;
  activeView: DcmView;
  showInvalidFields: boolean;
  comparisonMode: boolean;
  showDcmValidation: boolean;
  showInfoValidation: boolean;
}

class Dcm extends React.Component<RouteComponentProps<Props>, State> {
  static contextType = Made2FitAppContext;

  jobDocumentsService?: JobDocumentsService;
  jobsService?: JobsService;
  productDefinitionsService?: ProductDefinitionsService;
  toastService?: ToastService;
  tleService?: TleService;
  toast: React.RefObject<Toast>;

  constructor(props: RouteComponentProps<Props>) {
    super(props);
    this.state = {
      dcmPatch: {},
      jobPatch: {},
      dcmProductDefinitions: [],
      originalProductDefinitions: [],
      clonedItems: [],
      invalidItemMessagesMap: new Map<number, string[]>(),
      collapsedItemIndexes: new Set<number>(),
      activeView: 'measure',
      showInvalidFields: false,
      comparisonMode: false,
      showDcmValidation: false,
      showInfoValidation: false,
    };

    this.loadData = this.loadData.bind(this);
    this.loadDcm = this.loadDcm.bind(this);
    this.loadOriginal = this.loadOriginal.bind(this);
    this.loadProductDefinitions = this.loadProductDefinitions.bind(this);
    this.cloneItems = this.cloneItems.bind(this);
    this.onItemsChange = this.onItemsChange.bind(this);
    this.setInvalidItemMessagesMap = this.setInvalidItemMessagesMap.bind(this);
    this.onItemToggle = this.onItemToggle.bind(this);
    this.onViewChange = this.onViewChange.bind(this);
    this.onInstallationInfoChange = this.onInstallationInfoChange.bind(this);
    this.onItemsCollapse = this.onItemsCollapse.bind(this);
    this.onItemsExpand = this.onItemsExpand.bind(this);
    this.onDocumentSave = this.onDocumentSave.bind(this);
    this.onDocumentSaveAndNext = this.onDocumentSaveAndNext.bind(this);
    this.updateJobDocument = this.updateJobDocument.bind(this);
    this.onInfoSave = this.onInfoSave.bind(this);
    this.onInfoSaveAndNext = this.onInfoSaveAndNext.bind(this);
    this.onReleaseClick = this.onReleaseClick.bind(this);
    this.getSpeedDialItems = this.getSpeedDialItems.bind(this);
    this.onNoteChange = this.onNoteChange.bind(this);
    this.onComparisonModeToggle = this.onComparisonModeToggle.bind(this);
    this.onDcmValidateClick = this.onDcmValidateClick.bind(this);
    this.onInfoValidateClick = this.onInfoValidateClick.bind(this);
    this.toast = React.createRef();
  }

  async componentDidMount() {
    this.toastService = this.context.toastService;
    this.jobDocumentsService = this.context.jobDocumentsService;
    this.jobsService = this.context.jobsService;
    this.productDefinitionsService = this.context.productDefinitionsService;
    this.tleService = this.context.tleService;
    this.serCurrentTab();
    this.loadData();
  }

  async loadData() {
    const {jobId, jobDocumentId} = this.props.match.params;
    this.setState({loading: true});
    const {dcm, clonedItems} = (await this.loadDcm(jobId, jobDocumentId)) ?? {};
    const original = await this.loadOriginal(jobId);
    const revisionId = (dcm?.content as DcmContent)?.revision_id;
    const ownerId = dcm?.job?.owner_id;
    const originalRevisionId = (original?.content as CheckMeasureOriginalContent)?.revision_id;
    const {dcmProductDefinitions, originalProductDefinitions} =
      (await this.loadProductDefinitions(revisionId, originalRevisionId, ownerId ?? '')) ?? undefined;
    console.debug({
      loading: false,
      dcm,
      clonedItems: clonedItems ?? [],
      original: original ?? undefined,
      dcmProductDefinitions,
      originalProductDefinitions,
    });
    this.setState({
      loading: false,
      dcm,
      clonedItems: clonedItems ?? [],
      original: original ?? undefined,
      dcmProductDefinitions,
      originalProductDefinitions,
    });
  }

  async loadDcm(jobId: string, jobDocumentId: string) {
    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'job_id',
        value: jobId,
      })
    );
    filters.push(
      JSON.stringify({
        field: 'id',
        value: jobDocumentId,
      })
    );
    const aggregate: JobDocumentAggregate[] = ['job'];
    const params: QueryParameter = {
      filters: filters,
      aggregate: aggregate,
    };

    return this.jobDocumentsService
      ?.getJobDocuments(params)
      .then((data: ApiListResponse) => {
        const dcm = (data.records as JobDocument[])[0] ?? undefined;
        const dcmContent = dcm.content as DcmContent;
        const clonedItems = this.cloneItems(dcmContent.items ?? []);
        MessageService.sendMessage({
          name: config().messages.dcmViewed,
          value: `${dcm.job!.title} - DCM`,
        });
        return {dcm, clonedItems};
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Record load failed');
        console.log(error);
      });
  }

  async loadOriginal(jobId: string) {
    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'job_id',
        value: jobId,
      })
    );
    filters.push(
      JSON.stringify({
        field: 'type',
        value: 'Original',
      })
    );
    const params: QueryParameter = {
      filters: filters,
      aggregate: false,
    };

    return this.jobDocumentsService
      ?.getJobDocuments(params)
      .then((data: ApiListResponse) => {
        const original = (data.records as JobDocument[])[0] ?? undefined;
        const originalContent = original.content as OriginalContent;
        if (originalContent.requested_service === 'Check Measure & Install') {
          const checkMeasureContent = originalContent as CheckMeasureOriginalContent;
          (original.content as CheckMeasureOriginalContent).items = this.cloneItems(checkMeasureContent.items ?? []);
        }
        return original;
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Record load failed');
        console.log(error);
      });
  }

  async serCurrentTab() {
    const tab = this.props.match.params.tab as DcmView | undefined;
    const availableTabs: DcmView[] = ['release', 'info', 'measure'];
    if (tab && availableTabs.includes(tab)) {
      this.onViewChange(tab);
    }
  }

  isReadOnly(stage?: JobDocumentStage, jobDocumentSaving?: boolean): boolean {
    return !!(stage !== 'Measure In Progress' || jobDocumentSaving);
  }

  cloneItems(items: OrderItem[]) {
    const clonedItems = (structuredClone(items) as OrderItem[]).map(item => {
      const fields: Field[] = item.fields.map(field => {
        //we must convert field to class object
        const values = field.values.map(fieldValue => {
          if (fieldValue.sub_fields) {
            fieldValue.sub_fields = fieldValue.sub_fields.map(subField => {
              //we must convert subfield to class object
              return new Field(subField);
            });
          }
          return fieldValue;
        });
        return new Field({...field, values: values});
      });
      item.fields = fields;
      return new OrderItem(item);
    });
    return clonedItems;
  }

  async loadProductDefinitions(dcmRevisionId: number, originalRevisionId: number, ownerId: string) {
    const dcmProductDefinitionsPromise = this.productDefinitionsService!.getProductDefinitions(dcmRevisionId, ownerId);
    let originalProductDefinitionsPromise;
    if (originalRevisionId && originalRevisionId !== originalRevisionId) {
      originalProductDefinitionsPromise = this.productDefinitionsService!.getProductDefinitions(
        originalRevisionId,
        ownerId
      );
    }
    return Promise.all([dcmProductDefinitionsPromise, originalProductDefinitionsPromise]).then(
      ([dcmResult, originalResult]) => {
        console.debug({dcmResult, originalResult});
        const dcmProductDefinitions = (dcmResult.records as PaProductDefinition[]) ?? [];
        let originalProductDefinitions: PaProductDefinition[] = [];
        if (originalResult) {
          originalProductDefinitions = (originalResult?.records as PaProductDefinition[]) ?? [];
        } else if (dcmRevisionId === originalRevisionId) {
          originalProductDefinitions = dcmProductDefinitions;
        }
        console.debug({dcmProductDefinitions, originalProductDefinitions});
        return {
          dcmProductDefinitions,
          originalProductDefinitions,
        };
      }
    );
  }

  onItemsChange(newItems: OrderItem[]) {
    const {dcmPatch, dcm} = this.state;

    const updatedContent = (dcmPatch?.content ? {...dcmPatch.content} : {...dcm!.content}) as DcmContent;
    updatedContent.items = newItems;
    const updatedDcmPatch: JobDocumentPatch = {
      ...dcmPatch,
      content: updatedContent,
    };
    this.setState({
      dcmPatch: updatedDcmPatch,
      clonedItems: newItems,
    });
  }

  setInvalidItemMessagesMap(newInvalidItemMessagesMap: Map<number, string[]>) {
    this.setState({
      invalidItemMessagesMap: newInvalidItemMessagesMap,
    });
  }

  getValidationMessages() {
    const {invalidItemMessagesMap, dcm, dcmPatch, showDcmValidation, showInfoValidation} = this.state;
    const content = (dcmPatch?.content ?? dcm?.content) as DcmContent;
    const installationInfo = content.installation_info;
    return [
      ...this.getInfoValidationMessages(showInfoValidation, installationInfo),
      ...this.getDcmValidationMessages(showDcmValidation, invalidItemMessagesMap, content),
    ];
  }

  getInfoValidationMessages(showInfoValidation: boolean, installationInfo?: InstallationInfo): ValidationMessage[] {
    const validationMessages: ValidationMessage[] = [];
    if (showInfoValidation) {
      if (installationInfo?.time_required === undefined) {
        validationMessages.push({
          message: 'Duration is mandatory field.',
          view: 'info',
        });
      } else if (installationInfo.time_required <= 0) {
        validationMessages.push({
          message: 'Duration must be greater than zero.',
          view: 'info',
        });
      }
      if (installationInfo?.fitters_required === undefined) {
        validationMessages.push({
          message: 'Fitters is mandatory field.',
          view: 'info',
        });
      } else if (installationInfo.fitters_required <= 0) {
        validationMessages.push({
          message: 'Fitters count must be greater than zero.',
          view: 'info',
        });
      }
    }
    return validationMessages;
  }

  getDcmValidationMessages(
    showDcmValidation: boolean,
    invalidItemMessagesMap?: Map<number, string[]>,
    content?: DcmContent
  ): ValidationMessage[] {
    const validationMessages: ValidationMessage[] = [];
    if (showDcmValidation) {
      if (invalidItemMessagesMap?.size) {
        for (const [itemIndex, messages] of Array.from(invalidItemMessagesMap.entries())) {
          for (const message of messages) {
            validationMessages.push({
              message: `Item ${itemIndex}: ${message}`,
              view: 'measure',
            });
          }
        }
      }
      if (!content?.items.length) {
        validationMessages.push({
          message: 'DCM content is empty.',
          view: 'measure',
        });
      }
    }
    return validationMessages;
  }

  onItemToggle(index: number) {
    const newCollapsedItemIndexes = new Set(this.state.collapsedItemIndexes);
    if (newCollapsedItemIndexes.has(index)) {
      newCollapsedItemIndexes.delete(index);
    } else {
      newCollapsedItemIndexes.add(index);
    }
    this.setState({collapsedItemIndexes: newCollapsedItemIndexes});
  }

  onViewChange(newView: DcmView) {
    this.setState(state => {
      let showDcmValidation = state.showDcmValidation;
      let showInfoValidation = state.showInfoValidation;
      let showInvalidFields = state.showInvalidFields;
      if (!this.isReadOnly(state.dcm?.stage)) {
        if (newView === 'release') {
          showDcmValidation = true;
          showInfoValidation = true;
          showInvalidFields = true;
        }
      }
      return {
        activeView: newView,
        showDcmValidation: showDcmValidation,
        showInfoValidation: showInfoValidation,
        showInvalidFields: showInvalidFields,
      };
    });
  }

  onInstallationInfoChange(fieldsToUpdate: Partial<InstallationInfo>) {
    const {dcmPatch, dcm} = this.state;
    const currentInstallInfo =
      dcmPatch && dcmPatch.content && (dcmPatch.content as DcmContent).installation_info
        ? (dcmPatch.content as DcmContent).installation_info
        : dcm && dcm.content && (dcm.content as DcmContent).installation_info
          ? (dcm.content as DcmContent).installation_info
          : {};
    const newDcmContentPatch: DcmContent = {
      ...(dcm!.content as DcmContent),
      ...(dcmPatch.content as DcmContent),
      installation_info: {
        ...currentInstallInfo,
        ...fieldsToUpdate,
      } as InstallationInfo,
    };
    this.setState({
      dcmPatch: {
        ...dcmPatch,
        content: newDcmContentPatch,
      },
    });
  }

  onNoteChange(value: string) {
    const {dcmPatch, dcm} = this.state;
    const updatedContent = (dcmPatch?.content ? {...dcmPatch.content} : {...dcm!.content}) as DcmContent;
    updatedContent.release_note = value;
    const updatedDcmPatch: JobDocumentPatch = {
      ...dcmPatch,
      content: updatedContent,
    };
    this.setState({dcmPatch: updatedDcmPatch});
  }

  onItemsCollapse() {
    const itemIndexes = this.state.clonedItems.map(item => item.index);
    this.setState({collapsedItemIndexes: new Set<number>(itemIndexes)});
  }

  onItemsExpand() {
    this.setState({collapsedItemIndexes: new Set<number>()});
  }

  onComparisonModeToggle() {
    this.setState({comparisonMode: !this.state.comparisonMode});
  }

  onDcmValidateClick() {
    const {invalidItemMessagesMap, dcmPatch, dcm} = this.state;
    const content = (dcmPatch?.content ?? dcm?.content) as DcmContent;
    const dcmValidationMessages = this.getDcmValidationMessages(true, invalidItemMessagesMap, content);
    if (dcmValidationMessages.length) {
      const errorText = (
        <div>
          {dcmValidationMessages.map((validationMessage, index) => {
            return <li key={index}>{validationMessage.message}</li>;
          })}
        </div>
      );
      MessageService.sendMessage(config().messages.orderCanNotBeSaved);
      this.toastService?.showError(this.toast, errorText);
    } else {
      this.toastService?.showSuccess(this.toast, 'Items are valid.');
    }
    this.setState({showDcmValidation: true, showInvalidFields: true});
  }

  onInfoValidateClick() {
    const {dcmPatch, dcm} = this.state;
    const content = (dcmPatch?.content ?? dcm?.content) as DcmContent;
    const dcmValidationMessages = this.getInfoValidationMessages(true, content?.installation_info);
    if (dcmValidationMessages.length) {
      const errorText = (
        <div>
          {dcmValidationMessages.map((validationMessage, index) => {
            return <li key={index}>{validationMessage.message}</li>;
          })}
        </div>
      );
      this.toastService?.showError(this.toast, errorText);
    } else {
      this.toastService?.showSuccess(this.toast, 'Installation info is valid.');
    }
    this.setState({showInfoValidation: true});
  }

  async updateJobDocument(dcm: JobDocument, dcmPatch: JobDocumentPatch) {
    if (Object.keys(dcmPatch).length) {
      this.setState({jobDocumentSaving: true});
      return this.jobDocumentsService
        ?.updateJobDocument(dcm!.id!, dcmPatch)
        .then((dcm: JobDocument) => {
          this.toastService?.showSuccess(this.toast, 'DCM updated successfully.');
          this.setState({dcmPatch: {}});
          this.loadDcm(dcm.job_id, dcm.id!);
          return dcm;
        })
        .catch(error => {
          this.toastService?.showError(this.toast, 'Sorry, DCM update failed, please try again.');
          console.error('error: ' + error);
        })
        .finally(() => {
          this.setState({jobDocumentSaving: false});
        });
    }
  }

  onDocumentSave() {
    const {dcm, dcmPatch} = this.state;
    this.updateJobDocument(dcm!, dcmPatch);
  }

  async onDocumentSaveAndNext() {
    const {dcm, dcmPatch} = this.state;
    const updatedDcm = await this.updateJobDocument(dcm!, dcmPatch);
    if (updatedDcm) {
      this.setState({activeView: 'info'});
    }
  }

  onInfoSave() {
    const {dcm, dcmPatch} = this.state;
    this.updateJobDocument(dcm!, dcmPatch);
  }

  async onInfoSaveAndNext() {
    const {dcm, dcmPatch} = this.state;
    await this.updateJobDocument(dcm!, dcmPatch);
    this.setState({activeView: 'release'});
  }

  onReleaseClick() {
    const {dcm, dcmPatch} = this.state;
    this.releaseJobDocument(dcm!, {...dcmPatch, stage: 'Measure Released'});
  }

  async releaseJobDocument(dcm: JobDocument, dcmPatch: JobDocumentPatch) {
    const {history} = this.props;
    this.setState({jobDocumentReleasing: true});
    const jobDocument = await this.updateJobDocument(dcm!, dcmPatch);
    let releaseNote: string | undefined;
    if (dcmPatch && dcmPatch.content && (dcmPatch.content as DcmContent).release_note) {
      releaseNote = (dcmPatch.content as DcmContent).release_note;
    } else if (dcm && dcm.content && (dcm.content as DcmContent).release_note) {
      releaseNote = (dcm.content as DcmContent).release_note;
    }
    const tle: TimeLineEvent = {
      event_type: 'job_doc_event',
      entity_type: 'job',
      recorded_by: this.getCurrentUserId(),
      entity_id: dcm?.job_id ?? '',
      content: {
        doc_type: 'DCM',
        doc_event_type: 'release',
        note: releaseNote,
      } as TleContentJobDocEvent,
      recorded_at: new Date(),
    };
    await this.tleService?.createTle(tle);
    this.setState({jobDocumentReleasing: false});
    if (jobDocument) {
      history.push(`/job/${dcm?.job_id}`);
    }
  }

  getCurrentUserId() {
    const unparsedUser: string = localStorage.getItem('user') ?? '';
    const currentUser = JSON.parse(unparsedUser);
    const userId = currentUser?.uuid ?? '';
    return userId;
  }

  getSpeedDialItems(readOnly: boolean): MenuItem[] {
    const {activeView, comparisonMode} = this.state;
    const speedDialItems: MenuItem[] = [];
    if (activeView === 'measure') {
      if (!readOnly) {
        speedDialItems.push(
          {
            template: (
              <TwoSpeedDialItem
                icon={faFloppyDiskCircleArrowRight}
                label="Save & Next"
                onClick={this.onDocumentSaveAndNext}
              />
            ),
          },
          {
            template: <TwoSpeedDialItem icon={faFloppyDisk} label="Save" onClick={this.onDocumentSave} />,
          },
          {
            template: <TwoSpeedDialItem icon={faCheck} label="Validate" onClick={this.onDcmValidateClick} />,
          }
        );
      }
      if (comparisonMode) {
        speedDialItems.push({
          template: <TwoSpeedDialItem icon={faEyeSlash} label="Hide Changes" onClick={this.onComparisonModeToggle} />,
        });
      } else {
        speedDialItems.push({
          template: <TwoSpeedDialItem icon={faEye} label="Show Changes" onClick={this.onComparisonModeToggle} />,
        });
      }

      if (this.state.clonedItems.length) {
        const allItemsMinimised = this.state.clonedItems.every(item => this.state.collapsedItemIndexes.has(item.index));
        if (allItemsMinimised) {
          speedDialItems.push({
            template: <TwoSpeedDialItem icon={faArrowsMaximize} label="Expand" onClick={this.onItemsExpand} />,
          });
        } else {
          speedDialItems.push({
            template: <TwoSpeedDialItem icon={faArrowsMinimize} label="Collapse" onClick={this.onItemsCollapse} />,
          });
        }
      }
    } else if (activeView === 'info') {
      if (!readOnly) {
        speedDialItems.push(
          {
            template: (
              <TwoSpeedDialItem
                icon={faFloppyDiskCircleArrowRight}
                label="Save & Next"
                onClick={this.onInfoSaveAndNext}
              />
            ),
          },
          {
            template: <TwoSpeedDialItem icon={faFloppyDisk} label="Save" onClick={this.onInfoSave} />,
          },
          {
            template: <TwoSpeedDialItem icon={faCheck} label="Validate" onClick={this.onInfoValidateClick} />,
          }
        );
      }
    }
    return speedDialItems;
  }

  render() {
    const {
      dcm,
      clonedItems,
      dcmProductDefinitions,
      originalProductDefinitions,
      collapsedItemIndexes,
      activeView,
      dcmPatch,
      original,
      comparisonMode,
      showInfoValidation,
      showInvalidFields,
      loading,
      jobDocumentSaving,
      jobDocumentReleasing,
    } = this.state;

    if (loading) {
      return (
        <div className="p-d-flex p-ai-center w-100 h-100">
          <ProgressSpinner />
        </div>
      );
    }

    if (!dcm) {
      return <></>;
    }

    const readOnly = this.isReadOnly(dcm.stage, jobDocumentSaving);
    const validationMessages = this.getValidationMessages();
    const speedDialItems: MenuItem[] = this.getSpeedDialItems(readOnly);

    const hideReleaseButton =
      (readOnly && dcm.job?.stage !== 'Measure Finished' && dcm.job?.stage !== 'Measure Started') ||
      !!validationMessages.length;

    const dcmContent = dcm.content as DcmContent;
    const dcmPatchContent = dcmPatch.content as DcmContent;

    let originalItems = undefined;
    if (original?.content) {
      const originalContent = original.content as OriginalContent;
      if (originalContent.requested_service === 'Check Measure & Install') {
        const checkMeasureContent = originalContent as CheckMeasureOriginalContent;
        originalItems = checkMeasureContent.items;
      } else if (originalContent.requested_service === 'Consult & Install') {
        const consultContent = originalContent as ConsultOriginalContent;
        if (consultContent.detail_level === 'draft' || consultContent.detail_level === 'estimate') {
          originalItems = this.cloneItems(consultContent.items ?? []);
        }
      }
    }

    return (
      <>
        <div id="dcm_page" className="app-page">
          <div className="dcm-content">
            <ScrollPanel className="p-mt-1 dcm-scroll-panel">
              {activeView === 'measure' && (
                <MeasureView
                  items={clonedItems ?? []}
                  originalItems={originalItems ?? []}
                  productDefinitions={dcmProductDefinitions}
                  originalProductDefinitions={originalProductDefinitions}
                  onItemsChange={this.onItemsChange}
                  setInvalidItemMessagesMap={this.setInvalidItemMessagesMap}
                  collapsedItemIndexes={collapsedItemIndexes}
                  onItemToggle={this.onItemToggle}
                  readOnly={readOnly}
                  comparisonMode={comparisonMode}
                  showInvalidFields={showInvalidFields}
                />
              )}
              {activeView === 'info' && (
                <InfoView
                  installationInfo={dcmPatchContent?.installation_info ?? dcmContent.installation_info}
                  onInstallationInfoChange={this.onInstallationInfoChange}
                  readOnly={readOnly}
                  showValidation={showInfoValidation}
                />
              )}
              {activeView === 'release' && (
                <ReleaseView
                  validationMessages={validationMessages}
                  onReleaseClick={this.onReleaseClick}
                  hideReleaseButton={hideReleaseButton}
                  releaseNote={dcmPatchContent?.release_note ?? dcmContent?.release_note ?? ''}
                  onReleaseNoteChange={this.onNoteChange}
                  jobDocumentReleasing={jobDocumentReleasing}
                />
              )}
            </ScrollPanel>
          </div>
          <BottomTabMenu
            activeView={activeView}
            onViewChange={this.onViewChange}
            validationMessages={validationMessages}
            readOnly={readOnly}
            disabled={jobDocumentSaving || jobDocumentReleasing}
          />
          {!jobDocumentSaving && <TwoSpeedDial model={speedDialItems} bottomPosition="80px" />}
        </div>
        {jobDocumentSaving && !jobDocumentReleasing && (
          <div className="overlay">
            <ProgressSpinner className="overlay-spinner" />
          </div>
        )}
        <Toast ref={this.toast} />
      </>
    );
  }
}

export default withRouter(Dcm);
