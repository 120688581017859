export const config = (companyId?: string) => {
  const fittingService = process.env.REACT_APP_FITTING_SERVICE;
  const productService = process.env.REACT_APP_PRODUCT_SERVICE;
  const tleService = process.env.REACT_APP_TLE_SERVICE;
  const companyService = process.env.REACT_APP_CUSTOMER_SERVICE;

  return {
    cognito: {
      poolid: process.env.REACT_APP_COGNITO_POOL_ID,
      clientid: process.env.REACT_APP_ID,
    },
    endpoints: {
      jobs: fittingService + `api/v2/${companyId}/jobs`,
      jobDocuments: fittingService + `api/v2/${companyId}/job-documents`,
      appointments: fittingService + `api/v2/${companyId}/appointments`,
      productDefinitions: productService + `api/v2/${companyId}/products-definitions`,
      tles: tleService + 'tles',
      companies: companyService + 'api/v2/companies',
      me: companyService + 'api/v2/my-contact',
    },
    values: {
      defaultTimeZone: 'Australia/Brisbane',
      stopTypingDetection: 1000,
    },
    messages: {
      loggedIn: 'loggedin',
      loggedOut: 'loggedout',
      schedulerViewed: 'scheduler-viewed',
      jobViewed: 'job-viewed',
      dcmViewed: 'dcm-viewed',
      originalRequestViewed: 'original-request-viewed',
      topSelectionChanged: 'top-selection-changed',
      topSelectionDataLoaded: 'top-selection-data-loaded',
      orderCanNotBeSaved: 'order-can-not-be-saved',
    },
    ls_keys: {
      jobs_search_value: 'jobs-search-value',
      schedule_open_tabs: 'schedule-open-tabs-array',
      current_company: 'current company',
      current_role: 'current role',
    },
    stopTypingDetection: 1000,
  };
};
export default config;
