import React from 'react';
import {OrderItemsComponent} from 'two-app-ui';
import {OrderItem, PaProductDefinition} from 'two-core';

interface Props {
  items: OrderItem[];
  productDefinitions: PaProductDefinition[];
  originalProductDefinitions: PaProductDefinition[];
  onItemsChange: (newItems: OrderItem[]) => void;
  setInvalidItemMessagesMap?: (invalidItemMessagesMap: Map<number, string[]>) => void;
  collapsedItemIndexes?: Set<number>;
  onItemToggle?: (index: number) => void;
  readOnly: boolean;
  originalItems?: OrderItem[];
  comparisonMode: boolean;
  showInvalidFields: boolean;
}

export const MeasureView = ({
  items,
  productDefinitions,
  originalProductDefinitions,
  onItemsChange,
  onItemToggle,
  setInvalidItemMessagesMap,
  collapsedItemIndexes,
  readOnly,
  originalItems,
  comparisonMode,
  showInvalidFields,
}: Props) => {
  return (
    <OrderItemsComponent
      mode={readOnly ? 'readonly' : 'advanced_edit'}
      items={items}
      productDefinitions={productDefinitions}
      originalProductDefinitions={originalProductDefinitions}
      onItemsChanged={onItemsChange}
      setInvalidItemMessagesMap={setInvalidItemMessagesMap}
      outerItemColumnClassName={'p-col-12 p-md-6'}
      innerItemColumnClassName={'p-col-12'}
      collapsedItemIndexes={collapsedItemIndexes}
      onItemToggle={onItemToggle}
      showCopyLastButton
      originalItems={originalItems}
      comparisonMode={comparisonMode}
      showInvalidFields={showInvalidFields}
    />
  );
};
