import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, ApiResponse, JobDocument, JobDocumentPatch, QueryParameter} from 'two-core';

export default class JobDocumentsService extends ApiService {
  constructor(aus: AuthService) {
    super(aus);
  }

  async getJobDocuments(params: QueryParameter): Promise<ApiListResponse> {
    const companyId = localStorage.getItem(config().ls_keys.current_company)!;
    return this.get(config(companyId).endpoints.jobDocuments, params)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  async updateJobDocument(id: string, data: JobDocumentPatch): Promise<JobDocument> {
    const companyId = localStorage.getItem(config().ls_keys.current_company)!;
    return this.patch(config(companyId).endpoints.jobDocuments + '/' + id, data)
      .then(data => {
        return Promise.resolve(data as JobDocument);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }
}
