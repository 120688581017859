import React from 'react';
import {getTwoDateFormat, ToastService, TwoDialog, UsersService} from 'two-app-ui';
import {Appointment, AppointmentPatch, AppointmentType, Job, QueryParameter} from 'two-core';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import {Calendar, CalendarChangeParams} from 'primereact/calendar';
import {InputTextarea} from 'primereact/inputtextarea';
import {appointmentStages, appointmentTypes} from '../../config/values';
import {Button} from 'primereact/button';
import {Toast} from 'primereact/toast';
import Made2FitAppContext from '../../context/Made2FitAppContext';
import {DateTime} from 'luxon';
import AppointmentsService from '../../services/AppointmentsService';

interface Props {
  showDialog: boolean;
  job: Job;
  onBook: (appointmentPatch: AppointmentPatch) => void;
  onCancel: () => void;
  saving: boolean;
  toast: React.RefObject<Toast>;
}

interface State {
  appointmentPatch: AppointmentPatch;
}

export class BookAppointmentDialog extends React.Component<Props, State> {
  static contextType = Made2FitAppContext;
  appointmentsService?: AppointmentsService;
  usersService?: UsersService;

  toastService: ToastService | null = null;
  constructor(props: Props) {
    super(props);

    this.state = {
      appointmentPatch: {},
    };

    this.onShow = this.onShow.bind(this);
    this.onBookClicked = this.onBookClicked.bind(this);
    this.onCancelClicked = this.onCancelClicked.bind(this);
    this.onAppointmentTypeChange = this.onAppointmentTypeChange.bind(this);
    this.onAppointmentStageChange = this.onAppointmentStageChange.bind(this);
    this.onStartPlanChange = this.onStartPlanChange.bind(this);
    this.onEndPlanChange = this.onEndPlanChange.bind(this);
    this.onNoteChange = this.onNoteChange.bind(this);
    this.validate = this.validate.bind(this);
  }

  async componentDidMount() {
    this.toastService = this.context.toastService;
    this.appointmentsService = this.context.appointmentsService;
    this.usersService = this.context.usersService;
  }

  onShow() {
    this.loadAppointment();
  }

  async loadAppointment() {
    const filters = [
      JSON.stringify({
        field: 'job_id',
        value: this.props.job.id,
      }),
      JSON.stringify({
        field: 'end_real',
        condition: 'isNull',
      }),
    ];

    const params: QueryParameter = {
      filters: filters,
    };

    this.appointmentsService?.getAppointments(params).then(response => {
      const appointment = (response.records as Appointment[])[0];
      let appPatch: AppointmentPatch = {};
      if (appointment) {
        // update
        appPatch = {
          type: appointment.type,
          stage: appointment.stage,
          start_plan: appointment.start_plan ? new Date(appointment.start_plan) : new Date(),
          end_plan: appointment.end_plan ? new Date(appointment.end_plan) : undefined,
          note: appointment.note,
        };
      }

      if (this.props.job.stage === 'New' || this.props.job.stage === 'Assigned') {
        if (this.props.job.requested_services === 'Check Measure & Install') {
          appPatch.type = 'Check Measure';
        } else if (this.props.job.requested_services === 'Consult & Install') {
          appPatch.type = 'Consultation';
        } else if (this.props.job.requested_services === 'Service Call') {
          appPatch.type = 'Service Call';
        }
      } else if (['In Production', 'In Shipping', 'Delivered'].includes(this.props.job.stage)) {
        if (
          ['Check Measure & Install', 'Consult & Install', 'Service Call'].includes(this.props.job.requested_services)
        ) {
          appPatch.type = 'Installation';
        } else if (this.props.job.requested_services === 'Repair') {
          appPatch.type = 'Repair';
        }
      }

      if (!appPatch.type) {
        appPatch.type = 'Installation';
      }
      if (!appPatch.stage) {
        appPatch.stage = 'Booked';
      }
      if (!appPatch.start_plan) {
        appPatch.start_plan = new Date();
        appPatch.end_plan = this.calcEndDate(new Date(), appPatch.type);
      }

      this.setState({appointmentPatch: appPatch});
    });
  }

  onBookClicked() {
    const {job} = this.props;
    if (this.validate()) {
      const appointmentPatch = {...this.state.appointmentPatch};
      appointmentPatch.title = `${appointmentPatch.type} for ${job.title}`;
      appointmentPatch.job_id = job.id;
      appointmentPatch.address = job.address;
      this.props.onBook(appointmentPatch);
    }
  }

  validate(): boolean {
    const appointmentPatch = {...this.state.appointmentPatch};

    const errors: string[] = [];
    if (!appointmentPatch?.type) {
      errors.push('Type field is empty.');
    }
    if (!appointmentPatch?.stage) {
      errors.push('Stage field is empty.');
    }

    if (appointmentPatch?.start_plan && appointmentPatch?.end_plan) {
      const startDate = DateTime.fromJSDate(appointmentPatch?.start_plan);
      const endDate = DateTime.fromJSDate(appointmentPatch?.end_plan);
      if (startDate > endDate) {
        errors.push('Start date is greater than end date.');
      }
    } else {
      if (!appointmentPatch?.start_plan) {
        errors.push('Start field is empty.');
      }
      if (!appointmentPatch?.end_plan) {
        errors.push('End field is empty.');
      }
    }

    if (errors.length) {
      const errorText = (
        <div>
          Form is invalid:
          {errors.map((error, index) => {
            return <li key={index}>{error}</li>;
          })}
        </div>
      );
      this.toastService?.showError(this.props.toast, errorText);
      return false;
    }
    return true;
  }

  onCancelClicked() {
    this.setState({appointmentPatch: {}});
    this.props.onCancel();
  }

  onAppointmentTypeChange(e: DropdownChangeParams) {
    this.setState({
      appointmentPatch: {...this.state.appointmentPatch, type: e.value},
    });
  }

  onAppointmentStageChange(e: DropdownChangeParams) {
    this.setState({
      appointmentPatch: {...this.state.appointmentPatch, stage: e.value},
    });
  }

  calcEndDate(start: Date, appType?: AppointmentType): Date {
    if (!appType) {
      return start;
    }
    let result: Date = new Date();
    switch (appType) {
      case 'Consultation':
        result = new Date(DateTime.fromJSDate(start).plus({hours: 2}).toJSDate());
        break;
      case 'Check Measure':
      case 'Service Call':
        result = new Date(DateTime.fromJSDate(start).plus({hours: 1}).toJSDate());
        break;
      case 'Installation':
      case 'Repair':
        if (this.props.job.installation_info) {
          result = new Date(
            DateTime.fromJSDate(start)
              .plus({
                hours: this.props.job.installation_info.time_required,
              })
              .toJSDate()
          );
        }
        break;
    }
    return result;
  }

  onStartPlanChange(e: CalendarChangeParams) {
    const startDate = e.value as Date;
    const endDate = this.calcEndDate(startDate, this.state.appointmentPatch.type);

    this.setState({
      appointmentPatch: {
        ...this.state.appointmentPatch,
        start_plan: startDate,
        end_plan: endDate,
      },
    });
  }

  onEndPlanChange(e: CalendarChangeParams) {
    this.setState({
      appointmentPatch: {
        ...this.state.appointmentPatch,
        end_plan: e.value as Date,
      },
    });
  }

  onNoteChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    this.setState({
      appointmentPatch: {
        ...this.state.appointmentPatch,
        note: e.target.value,
      },
    });
  }

  render() {
    const {showDialog, job, saving} = this.props;
    const {appointmentPatch} = this.state;
    const calendarInputDate = getTwoDateFormat(this.usersService?.settings?.date_format, 'calendarInputDate');
    const calendarInputHour = getTwoDateFormat(this.usersService?.settings?.date_format, 'calendarInputHour');
    const content = (
      <div id="book_appointment_dialog" className="w-100">
        <div className="p-field p-grid p-ml-0">
          <label className="p-col-2 p-md-1">
            <small>type</small>
          </label>
          <div className="p-col-10 p-md-5 p-mb-2">
            <Dropdown
              className={'w-100'}
              value={appointmentPatch.type}
              options={appointmentTypes}
              onChange={this.onAppointmentTypeChange}
              disabled={true}
            />
          </div>
          <label className="p-col-2 p-md-1">
            <small>stage</small>
          </label>
          <div className="p-col-10 p-md-5 p-mb-2">
            <Dropdown
              className={'w-100'}
              value={appointmentPatch.stage}
              options={appointmentStages}
              onChange={this.onAppointmentStageChange}
            />
          </div>
        </div>
        <div className="p-field p-grid p-ml-0">
          <label className="p-col-2 p-md-1">
            <small>start</small>
          </label>
          <div className="p-col-10 p-md-5 p-mb-2">
            <Calendar
              className="w-100"
              value={appointmentPatch.start_plan}
              onChange={this.onStartPlanChange}
              showTime
              hourFormat={calendarInputHour}
              dateFormat={calendarInputDate}
            />
          </div>
          <label className="p-col-2 p-md-1">
            <small>end</small>
          </label>
          <div className="p-col-10 p-md-5 p-mb-2">
            <Calendar
              className="w-100"
              value={appointmentPatch.end_plan}
              onChange={this.onEndPlanChange}
              showTime
              hourFormat={calendarInputHour}
              dateFormat={calendarInputDate}
            />
          </div>
        </div>
        <div className="p-field p-grid p-ml-0 p-mb-0">
          <label className="p-col-2 p-md-1">
            <small>note</small>
          </label>
          <div className="p-col-10 p-md-11">
            <InputTextarea className="w-100" rows={5} value={appointmentPatch.note} onChange={this.onNoteChange} />
          </div>
        </div>
      </div>
    );

    const footer = (
      <div className={'p-d-flex p-justify-end'}>
        <Button label="Cancel" className="p-mr-2 p-button-text" onClick={this.onCancelClicked} />
        <Button loading={saving} label="Book" onClick={this.onBookClicked} autoFocus />
      </div>
    );

    return (
      <TwoDialog
        headerTitle={`Book Appointment for ${job.title}`}
        loading={false}
        showDialog={showDialog}
        style={{width: '50vw'}}
        breakpoints={{'768px': '80vw', '576px': '95vw'}}
        footer={footer}
        saveButtonTitle="Book"
        contentClassName="w-100"
        onHide={this.onCancelClicked}
        onShow={this.onShow}
      >
        {content}
      </TwoDialog>
    );
  }
}
