import React from 'react';
import {ScrollPanel} from 'primereact/scrollpanel';
import {MessageService, OrderItemsComponent, ToastService} from 'two-app-ui';
import {
  ApiListResponse,
  CheckMeasureOriginalContent,
  ConsultOriginalContent,
  Field,
  JobDocument,
  JobDocumentAggregate,
  OrderItem,
  OriginalContent,
  PaProductDefinition,
  QueryParameter,
} from 'two-core';
import {ProgressSpinner} from 'primereact/progressspinner';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import Made2FitAppContext from '../../../context/Made2FitAppContext';
import {Toast} from 'primereact/toast';
import JobDocumentsService from '../../../services/JobDocumentsService';
import './OriginalRequest.scss';
import ProductDefinitionsService from '../../../services/ProductDefinitionsService';
import {config} from '../../../config/config';

interface Props {
  jobId: string;
  jobDocumentId: string;
}

interface State {
  loading?: boolean;
  productDefinitionsLoading?: boolean;
  originalRequest?: JobDocument;
  productDefinitions: PaProductDefinition[];
}

class OriginalRequest extends React.Component<RouteComponentProps<Props>, State> {
  static contextType = Made2FitAppContext;

  jobDocumentsService?: JobDocumentsService;
  productDefinitionsService?: ProductDefinitionsService;
  toastService?: ToastService;
  toast: React.RefObject<Toast>;

  constructor(props: RouteComponentProps<Props>) {
    super(props);
    this.state = {
      loading: false,
      productDefinitionsLoading: false,
      productDefinitions: [],
    };

    this.loadData = this.loadData.bind(this);
    this.loadOriginalRequest = this.loadOriginalRequest.bind(this);
    this.loadProductDefinitions = this.loadProductDefinitions.bind(this);
    this.toast = React.createRef();
  }

  async componentDidMount() {
    this.toastService = this.context.toastService;
    this.jobDocumentsService = this.context.jobDocumentsService;
    this.productDefinitionsService = this.context.productDefinitionsService;
    this.loadData();
  }

  async loadData() {
    const jobDoc = await this.loadOriginalRequest();
    if (jobDoc && jobDoc.type === 'Original') {
      const originalContent = jobDoc.content as OriginalContent;
      if (
        originalContent.requested_service === 'Consult & Install' &&
        (originalContent as ConsultOriginalContent).revision_id
      ) {
        this.loadProductDefinitions((originalContent as ConsultOriginalContent).revision_id!);
      } else if (
        originalContent.requested_service === 'Check Measure & Install' &&
        (originalContent as CheckMeasureOriginalContent).revision_id
      ) {
        this.loadProductDefinitions((originalContent as CheckMeasureOriginalContent).revision_id!);
      }
    }
  }

  async loadOriginalRequest() {
    this.setState({loading: true});
    const jobId = this.props.match.params.jobId;
    const jobDocumentId = this.props.match.params.jobDocumentId;
    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'job_id',
        value: jobId,
      })
    );
    filters.push(
      JSON.stringify({
        field: 'id',
        value: jobDocumentId,
      })
    );
    const aggregate: JobDocumentAggregate[] = ['job'];
    const params: QueryParameter = {
      filters: filters,
      aggregate: aggregate,
    };

    return this.jobDocumentsService
      ?.getJobDocuments(params)
      .then((data: ApiListResponse) => {
        const originalRequest = (data.records as JobDocument[])[0] ?? [];
        const originalContent = originalRequest.content as OriginalContent;
        if (originalContent.requested_service === 'Consult & Install') {
          const items = (originalContent as ConsultOriginalContent).items;
          if (items) {
            (originalContent as ConsultOriginalContent).items = [...this.cloneDocItems(items)];
          }
        } else if (originalContent.requested_service === 'Check Measure & Install') {
          const items = (originalContent as CheckMeasureOriginalContent).items;
          if (items) {
            (originalContent as CheckMeasureOriginalContent).items = [...this.cloneDocItems(items)];
          }
        }

        MessageService.sendMessage({
          name: config().messages.originalRequestViewed,
          value: `${originalRequest.job!.title} - Original Request`,
        });
        this.setState({
          originalRequest: originalRequest,
        });
        return originalRequest;
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Record load failed');
        console.log(error);
      })
      .finally(() => this.setState({loading: false}));
  }

  cloneDocItems(items: OrderItem[]) {
    const clonedItems = (structuredClone(items) as OrderItem[]).map(item => {
      const fields: Field[] = item.fields.map(field => {
        //we must convert field to class object
        const values = field.values.map(fieldValue => {
          if (fieldValue.sub_fields) {
            fieldValue.sub_fields = fieldValue.sub_fields.map(subField => {
              //we must convert subfield to class object
              return new Field(subField);
            });
          }
          return fieldValue;
        });
        return new Field({...field, values: values});
      });
      item.fields = fields;
      return new OrderItem(item);
    });
    return clonedItems;
  }

  async loadProductDefinitions(revisionId: number) {
    this.setState({productDefinitionsLoading: true});
    return this.productDefinitionsService
      ?.getProductDefinitions(revisionId, this.state.originalRequest?.job?.owner_id)
      .then(data => {
        const productDefinitions = (data.records as PaProductDefinition[]) ?? [];
        this.setState({
          productDefinitions: productDefinitions,
        });
      })
      .finally(() => this.setState({productDefinitionsLoading: false}));
  }

  render() {
    const {loading, originalRequest} = this.state;

    if (loading) {
      return (
        <div className="p-d-flex p-ai-center w-100 h-100">
          <ProgressSpinner />
        </div>
      );
    }

    if (!originalRequest) {
      return <span>Sorry, No Data.</span>;
    }

    const originalContent = originalRequest.content as OriginalContent;
    const dealerInfo = originalContent.dealer_info;
    const clientInfo = originalContent.client_info;

    const dealerDetail = (
      <div className="p-my-4">
        <div className="p-field p-grid p-mb-1">
          <label className="p-col-2">dealership</label>
          <div className="p-col-10">{dealerInfo.company_name}</div>
        </div>
        <div className="p-field p-grid p-mb-1">
          <label className="p-col-2 p-as-start">dealer</label>
          <div className="p-col-10">
            {dealerInfo.first_name} {dealerInfo.last_name}
          </div>
        </div>
        <div className="p-field p-grid p-mb-1">
          <label className="p-col-2 p-as-start">email</label>
          <div className="p-col-10">{dealerInfo.email}</div>
        </div>
        <div className="p-field p-grid p-mb-1">
          <label className="p-col-2 p-as-start">phone</label>
          <div className="p-col-10">{dealerInfo.phone_number}</div>
        </div>
      </div>
    );

    const clientDetail = (
      <div className="p-my-4">
        <div className="p-field p-grid p-mb-1">
          <label className="p-col-2">client</label>
          <div className="p-col-10">
            {clientInfo.first_name} {clientInfo.last_name}
          </div>
        </div>
        <div className="p-field p-grid p-mb-1">
          <label className="p-col-2 p-as-start">address</label>
          <div className="p-col-10">
            {clientInfo.address.street}, {clientInfo.address.suburb}, {clientInfo.address.postCode},{' '}
            {clientInfo.address.state}
          </div>
        </div>
        {clientInfo.email && (
          <div className="p-field p-grid p-mb-1">
            <label className="p-col-2 p-as-start">email</label>
            <div className="p-col-10">{clientInfo.email}</div>
          </div>
        )}
        {clientInfo.phone_number && (
          <div className="p-field p-grid p-mb-1">
            <label className="p-col-2 p-as-start">phone</label>
            <div className="p-col-10">{clientInfo.phone_number}</div>
          </div>
        )}
      </div>
    );

    const generalData = (
      <div className="p-my-4">
        <div className="p-field p-grid p-mb-1">
          <label className="p-col-2">service</label>
          <div className="p-col-10">{originalContent.requested_service}</div>
        </div>
        {originalContent.comments && (
          <div className="p-field p-grid p-mb-1">
            <label className="p-col-2 p-as-start">comments</label>
            <div className="p-col-10">{originalContent.comments}</div>
          </div>
        )}
        {originalContent.attachments && originalContent.attachments.length > 0 && (
          <div className="p-field p-grid p-mb-1">
            <label className="p-col-2 p-as-start">attachments</label>
            <div className="p-col-10">{originalContent.attachments?.join(', ')}</div>
          </div>
        )}
      </div>
    );

    let sourceDetailLevel = <></>;
    let sourceEntity = <></>;
    let itemsComponent = <></>;
    if (originalContent.requested_service === 'Consult & Install') {
      const consultContent = originalContent as ConsultOriginalContent;
      sourceDetailLevel = (
        <div className="p-my-4">
          <div className="p-field p-grid p-mb-1">
            <label className="p-col-2">detail level</label>
            <div className="p-col-10">
              {consultContent.detail_level.charAt(0).toUpperCase() + consultContent.detail_level.slice(1)}
            </div>
          </div>
        </div>
      );
      if (consultContent.detail_level === 'products') {
        itemsComponent = (
          <div className="p-my-4">
            {consultContent.products?.map(prod => {
              return (
                <div className="p-field p-grid p-mb-1" key={prod.product_name}>
                  <div className="p-col-2" />
                  <div className="p-col-10">{`${prod.product_name} x ${prod.quantity}`}</div>„
                </div>
              );
            })}
          </div>
        );
      } else if (consultContent.detail_level === 'draft' || consultContent.detail_level === 'estimate') {
        sourceEntity = (
          <div className="p-my-4">
            <div className="p-field p-grid p-mb-1">
              <label className="p-col-2">code</label>
              <div className="p-col-10">
                {consultContent.detail_level === 'draft' ? consultContent.draft_code : consultContent.estimate_code}
              </div>
            </div>
          </div>
        );
        itemsComponent = (
          <OrderItemsComponent
            mode="readonly"
            items={consultContent.items ?? []}
            productDefinitions={this.state.productDefinitions}
            onItemsChanged={() => {}}
            outerItemColumnClassName={'p-col-12 p-md-6'}
            innerItemColumnClassName={'p-col-12'}
          />
        );
      }
    } else if (originalContent.requested_service === 'Check Measure & Install') {
      const measureContent = originalContent as CheckMeasureOriginalContent;
      sourceEntity = (
        <div className="p-my-4">
          <div className="p-field p-grid p-mb-1">
            <label className="p-col-2">code</label>
            <div className="p-col-10">{measureContent.estimate_code}</div>
          </div>
        </div>
      );
      itemsComponent = (
        <OrderItemsComponent
          mode="readonly"
          items={measureContent.items ?? []}
          productDefinitions={this.state.productDefinitions}
          onItemsChanged={() => {}}
          outerItemColumnClassName={'p-col-12 p-md-6'}
          innerItemColumnClassName={'p-col-12'}
        />
      );
    }

    return (
      <div id="original_page_container" className="p-mt-1">
        <div id="original_request_page" className="app-page">
          <ScrollPanel className="p-mt-1 drafts-scroll-panel">
            {dealerDetail}
            {clientDetail}
            {generalData}
            {sourceDetailLevel}
            {sourceEntity}
            {itemsComponent}
          </ScrollPanel>
        </div>
        <Toast ref={this.toast} />
      </div>
    );
  }
}

export default withRouter(OriginalRequest);
