import React from 'react';
import {Button} from 'primereact/button';
import {TwoDialog} from 'two-app-ui';

interface Props {
  showDialog: boolean;
  onNo: () => void;
  onYes: () => void;
  loading: boolean;
}

export const StartAppointmentDialog = ({showDialog, onNo, onYes, loading}: Props) => {
  const footer = (
    <div className={'p-d-flex p-justify-end'}>
      <Button label="No" className={'p-mr-2 p-button-text'} onClick={onNo} disabled={loading} />
      <Button label="Yes" onClick={onYes} disabled={loading} loading={loading} />
    </div>
  );
  return (
    <TwoDialog
      headerTitle={'Appointment start'}
      showDialog={showDialog}
      style={{width: '50vw'}}
      breakpoints={{'768px': '80vw', '576px': '95vw'}}
      onHide={() => {}}
      loading={false}
      footer={footer}
    >
      <div className="p-d-flex w-100">
        <span>Do you want open DCM?</span>
      </div>
    </TwoDialog>
  );
};
