import React, {useContext} from 'react';
import {Accordion, AccordionTab} from 'primereact/accordion';
import {InputTextarea} from 'primereact/inputtextarea';
import {Button} from 'primereact/button';
import {ValidationMessage} from '../../Dcm';
import './ReleaseView.scss';
import Made2FitAppContext from '../../../../../context/Made2FitAppContext';

interface Props {
  validationMessages: ValidationMessage[];
  releaseNote: string;
  onReleaseNoteChange: (value: string) => void;
  onReleaseClick: () => void;
  hideReleaseButton: boolean;
  jobDocumentReleasing?: boolean;
}

export const ReleaseView = ({
  validationMessages,
  releaseNote,
  onReleaseNoteChange,
  onReleaseClick,
  hideReleaseButton,
  jobDocumentReleasing,
}: Props) => {
  const context = useContext(Made2FitAppContext);
  const showReleaseNote = !!context.usersService?.settings?.ux?.made2fit_oba?.dcm?.release_note;
  const measureMessages = validationMessages.filter(message => message.view === 'measure');
  const infoMessages = validationMessages.filter(message => message.view === 'info');

  const accordionTabs: JSX.Element[] = [];
  if (measureMessages.length) {
    accordionTabs.push(
      <AccordionTab header="Measure" key="measure">
        {measureMessages.map((message, index) => (
          <div key={index} className="p-p-1">
            {message.message}
          </div>
        ))}
      </AccordionTab>
    );
  }
  if (infoMessages.length) {
    accordionTabs.push(
      <AccordionTab header="Info" key="info">
        {infoMessages.map((message, index) => (
          <div key={index} className="p-p-1">
            {message.message}
          </div>
        ))}
      </AccordionTab>
    );
  }

  return (
    <div className="p-mx-2">
      {!!accordionTabs.length && (
        <Accordion id="review-accordion" multiple className="w-100" activeIndex={Array.from(accordionTabs.keys())}>
          {accordionTabs}
        </Accordion>
      )}
      {showReleaseNote && (
        <div className="p-field p-grid p-my-2 p-mx-0">
          <label className="p-col-3 p-md-2 p-mb-0 p-pt-1 p-as-start">
            <small>release note</small>
          </label>
          <div className="p-col-9 p-md-10">
            <InputTextarea
              className="w-100"
              value={releaseNote}
              onChange={e => onReleaseNoteChange(e.target.value)}
              disabled={hideReleaseButton || jobDocumentReleasing}
            />
          </div>
        </div>
      )}

      {!hideReleaseButton && (
        <div className={'p-d-flex p-justify-end'}>
          <Button label="Release" className="release-button" onClick={onReleaseClick} loading={jobDocumentReleasing} />
        </div>
      )}
    </div>
  );
};
