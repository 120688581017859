import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, ApiResponse, Job, JobPatch, QueryParameter} from 'two-core';

export default class JobsService extends ApiService {
  constructor(aus: AuthService) {
    super(aus);
  }

  async getJobs(params: QueryParameter): Promise<ApiListResponse> {
    const companyId = localStorage.getItem(config().ls_keys.current_company)!;
    return this.get(config(companyId).endpoints.jobs, params)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  async updateJob(id: string, data: JobPatch): Promise<Job> {
    const companyId = localStorage.getItem(config().ls_keys.current_company)!;
    return this.patch(`${config(companyId).endpoints.jobs}/${id}`, data)
      .then(data => {
        return Promise.resolve(data as Job);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }
}
