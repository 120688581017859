import React from 'react';
import {ScrollPanel} from 'primereact/scrollpanel';
import {MessageService, ToastService, TwoSpeedDial, TwoSpeedDialItem} from 'two-app-ui';
import {
  ApiListResponse,
  Appointment,
  AppointmentPatch,
  AppointmentType,
  Job,
  JobAggregate,
  JobDocument,
  JobDocumentPatch,
  PaContact,
  QueryParameter,
  UserReference,
} from 'two-core';
import {ProgressSpinner} from 'primereact/progressspinner';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import Made2FitAppContext from '../../context/Made2FitAppContext';
import JobsService from '../../services/JobsService';
import {Toast} from 'primereact/toast';
import './JobDetail.scss';
import {BookAppointmentDialog} from './BookAppointmentDialog';
import AppointmentsService from '../../services/AppointmentsService';
import {JobDetailContent} from './Sections/JobDetailContent';
import {ContactsContent} from './Sections/ContactsContent';
import {InstallationInfoContent} from './Sections/InstallationInfoContent';
import {config} from '../../config/config';
import {AppointmentContent} from './Sections/AppointmentContent';
import {MenuItem} from 'primereact/menuitem';
import {faCalendar, faFileArrowUp, faFilePen, faPlay, faStop} from '@fortawesome/pro-regular-svg-icons';
import {DocumentsContent} from './Sections/DocumentsContent';
import {StartAppointmentDialog} from './StartAppointmentDialog';
import JobDocumentsService from '../../services/JobDocumentsService';
import {Button} from 'primereact/button';
import {OpenDcmDialog} from './OpenDcmDialog';
import {DateTime} from 'luxon';
import ContactsService from '../../services/ContactsService';

interface Props {
  id: string;
}

interface State {
  job?: Job;
  loading: boolean;
  showBookAppointmentDialog: boolean;
  showStartAppointmentDialog: boolean;
  showOpenDcmDialog: boolean;
  savingAppointment: boolean;
  startDialogLoading: boolean;
}

class JobDetail extends React.Component<RouteComponentProps<Props>, State> {
  static contextType = Made2FitAppContext;

  jobsService?: JobsService;
  appointmentsService?: AppointmentsService;
  jobDocumentsService?: JobDocumentsService;
  contactsService?: ContactsService;
  toastService?: ToastService;
  toast: React.RefObject<Toast>;

  constructor(props: RouteComponentProps<Props>) {
    super(props);
    this.state = {
      loading: false,
      showBookAppointmentDialog: false,
      showStartAppointmentDialog: false,
      showOpenDcmDialog: false,
      savingAppointment: false,
      startDialogLoading: false,
    };

    this.loadJob = this.loadJob.bind(this);
    this.onBookAppointment = this.onBookAppointment.bind(this);
    this.onCancelAppointment = this.onCancelAppointment.bind(this);
    this.onBookNowClicked = this.onBookNowClicked.bind(this);
    this.updateAppointment = this.updateAppointment.bind(this);
    this.updateJobDocument = this.updateJobDocument.bind(this);
    this.getSpeedDialItems = this.getSpeedDialItems.bind(this);
    this.onOriginalRequestClick = this.onOriginalRequestClick.bind(this);
    this.onDcmClick = this.onDcmClick.bind(this);
    this.onDcmOpen = this.onDcmOpen.bind(this);
    this.onBookClick = this.onBookClick.bind(this);
    this.onStartDialogYesClick = this.onStartDialogYesClick.bind(this);
    this.onStartDialogNoClick = this.onStartDialogNoClick.bind(this);
    this.startAppointment = this.startAppointment.bind(this);
    this.onFinishAppointmentClicked = this.onFinishAppointmentClicked.bind(this);
    this.createAppointment = this.createAppointment.bind(this);
    this.onOpenDcmDialogCancel = this.onOpenDcmDialogCancel.bind(this);
    this.toast = React.createRef();
  }

  async componentDidMount() {
    this.toastService = this.context.toastService;
    this.jobsService = this.context.jobsService;
    this.jobDocumentsService = this.context.jobDocumentsService;
    this.appointmentsService = this.context.appointmentsService;
    this.contactsService = this.context.contactsService;
    this.loadJob();
  }

  async loadJob() {
    this.setState({loading: true});
    const id = this.props.match.params.id;
    const filters: string[] = [];
    filters.push(
      JSON.stringify({
        field: 'id',
        value: id,
      })
    );
    const aggregate: JobAggregate[] = ['documents', 'appointments', 'owner', 'fitting_provider'];
    const params: QueryParameter = {
      filters: filters,
      aggregate: aggregate,
    };

    this.jobsService
      ?.getJobs(params)
      .then((data: ApiListResponse) => {
        const jobs = (data.records as Job[]) ?? [];
        const job = jobs[0];
        MessageService.sendMessage({
          name: config().messages.jobViewed,
          value: job.title,
        });
        this.setState({
          job: job,
        });
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Record load failed');
        console.log(error);
      })
      .finally(() => this.setState({loading: false}));
  }

  async startAppointment(appointment: Appointment, job: Job, reload = true) {
    const updatedAppointment = await this.updateAppointment(
      appointment.id!,
      {
        start_real: new Date(),
        stage: 'Started',
      },
      reload
    );
    if (!updatedAppointment) {
      return;
    }
    const currentUserId = this.getCurrentUserId();
    if (job.lead_fitter?.user_id !== currentUserId) {
      const myContact: PaContact | undefined = (await this.contactsService!.getMe()) as unknown as PaContact;
      if (myContact) {
        const leadFitterRef: UserReference = {
          user_id: myContact.user_id,
          contact_id: myContact.id,
          label: `${myContact.first_name} ${myContact.last_name}`,
        };
        await this.jobsService!.updateJob(job.id!, {lead_fitter: leadFitterRef});
      }
    }
    return updatedAppointment;
  }

  onBookNowClicked() {
    this.setState({showBookAppointmentDialog: true});
  }

  onBookAppointment(appointmentPatch: AppointmentPatch) {
    this.createAppointment(appointmentPatch, true);
  }

  createAppointment(appointmentPatch: AppointmentPatch, reload: boolean): Promise<Appointment | undefined> {
    this.setState({savingAppointment: true});
    return this.appointmentsService!.createAppointment(appointmentPatch)
      .then(appointment => {
        this.toastService?.showSuccess(this.toast, 'Appointment created successfully.');
        this.setState({showBookAppointmentDialog: false});
        if (reload) {
          this.loadJob();
        }
        return appointment;
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, appointment creation failed, please try again.');
        console.error('error: ' + error);
        return undefined;
      })
      .finally(() => {
        this.setState({savingAppointment: false});
      });
  }

  async updateAppointment(id: number, appointmentPatch: AppointmentPatch, reload: boolean) {
    this.setState({savingAppointment: true});
    return this.appointmentsService
      ?.updateAppointment(id, appointmentPatch)
      .then(appointment => {
        this.toastService?.showSuccess(this.toast, 'Appointment updated successfully.');
        this.setState({showBookAppointmentDialog: false});
        if (reload) {
          this.loadJob();
        }
        return appointment;
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, appointment update failed, please try again.');
        console.error('error: ' + error);
      })
      .finally(() => {
        this.setState({savingAppointment: false});
      });
  }

  async updateJobDocument(id: string, documentPatch: JobDocumentPatch) {
    this.setState({loading: true});
    return this.jobDocumentsService
      ?.updateJobDocument(id, documentPatch)
      .then((dcm: JobDocument) => {
        this.toastService?.showSuccess(this.toast, 'Document updated successfully.');
        return dcm;
      })
      .catch(error => {
        this.toastService?.showError(this.toast, 'Sorry, Document update failed, please try again.');
        console.error('error: ' + error);
      })
      .finally(() => {
        this.setState({loading: false});
      });
  }

  onCancelAppointment() {
    this.setState({showBookAppointmentDialog: false});
  }

  onOriginalRequestClick() {
    const {history} = this.props;
    const {job} = this.state;
    const originalRequest = job?.documents?.find(document => document.type === 'Original');
    history.push(`/job/${job!.id}/original-request/${originalRequest?.id}`);
  }

  onDcmClick() {
    const {job} = this.state;
    if (
      job?.stage === 'Measure Booked' ||
      job?.stage === 'Measure Planned' ||
      job?.stage === 'New' ||
      job?.stage === 'Assigned'
    ) {
      this.setState({showOpenDcmDialog: true});
    } else {
      this.onDcmOpen();
    }
  }

  onDcmOpen() {
    const {history} = this.props;
    const {job} = this.state;
    const dcm = job?.documents?.find(document => document.type === 'DCM');
    history.push(`/job/${job!.id}/dcm/${dcm?.id}`);
  }

  onReleaseDcmClick() {
    const {history} = this.props;
    const {job} = this.state;
    const dcm = job?.documents?.find(document => document.type === 'DCM');
    history.push(`/job/${job!.id}/dcm/${dcm?.id}/release`);
  }

  onBookClick(appointment: Appointment) {
    this.updateAppointment(
      appointment.id!,
      {
        stage: 'Booked',
      },
      true
    );
  }

  async onStartDialogYesClick() {
    const {job} = this.state;
    this.setState({startDialogLoading: true});
    let checkMeasureAppointment = this.getCurrentAppointment(job);
    if (!checkMeasureAppointment) {
      const newAppointmentPatch = this.getNewAppointment(job!);
      checkMeasureAppointment = await this.createAppointment(newAppointmentPatch, false);
    }
    await this.startAppointment(checkMeasureAppointment!, job!, false);
    this.onDcmOpen();
    this.setState({startDialogLoading: false});
  }

  async onOpenDcmDialogCancel() {
    this.setState({showOpenDcmDialog: false});
  }

  onStartDialogNoClick() {
    const {job} = this.state;
    this.setState({showStartAppointmentDialog: false}, () => {
      const checkMeasureAppointment = this.getCurrentAppointment(job)!;
      this.startAppointment(checkMeasureAppointment, job!, true);
    });
  }

  onFinishAppointmentClicked(appointment: Appointment) {
    this.updateAppointment(
      appointment.id!,
      {
        end_real: new Date(),
        stage: 'Finished',
      },
      true
    );
  }

  getCurrentUserId(): string {
    const unparsedUser: string = localStorage.getItem('user') ?? '';
    const currentUser = JSON.parse(unparsedUser);
    return currentUser?.uuid ?? '';
  }

  getNewAppointment(job: Job): AppointmentPatch {
    let type: AppointmentType;
    if (job.stage === 'New' || job.stage === 'Assigned') {
      if (job.requested_services === 'Check Measure & Install') {
        type = 'Check Measure';
      } else if (job.requested_services === 'Consult & Install') {
        type = 'Consultation';
      } else if (job.requested_services === 'Service Call') {
        type = 'Service Call';
      }
    } else if (['In Production', 'In Shipping', 'Delivered'].includes(job.stage)) {
      if (['Check Measure & Install', 'Consult & Install', 'Service Call'].includes(job.requested_services)) {
        type = 'Installation';
      } else if (job.requested_services === 'Repair') {
        type = 'Repair';
      }
    }

    const start = new Date();

    return {
      title: `${type!} for ${job.title}`,
      job_id: job.id,
      address: job.address,
      stage: 'Booked',
      type: type!,
      start_plan: start,
      end_plan: new Date(DateTime.fromJSDate(start).plus({hours: 1}).toJSDate()),
    };
  }

  getSpeedDialItems(): MenuItem[] {
    const speedDialItems: MenuItem[] = [];
    const {job} = this.state;
    const checkMeasureAppointment = this.getCurrentAppointment(job);
    if (job?.stage === 'New' || job?.stage === 'Assigned') {
      speedDialItems.push({
        template: <TwoSpeedDialItem icon={faCalendar} label="Plan / Book" onClick={() => this.onBookNowClicked()} />,
      });
    } else if (job?.stage === 'Measure Planned') {
      speedDialItems.push({
        template: <TwoSpeedDialItem icon={faCalendar} label="Book" onClick={() => this.onBookNowClicked()} />,
      });
    } else if (job?.stage === 'Measure Booked') {
      speedDialItems.push({
        template: (
          <TwoSpeedDialItem
            icon={faPlay}
            label="Start"
            onClick={() => this.setState({showStartAppointmentDialog: true})}
          />
        ),
      });
    } else if (job?.stage === 'Measure Started') {
      speedDialItems.push({
        template: <TwoSpeedDialItem icon={faFilePen} label="Edit DCM" onClick={() => this.onDcmOpen()} />,
      });
      speedDialItems.push({
        template: (
          <TwoSpeedDialItem
            icon={faStop}
            label="Finish"
            onClick={() => this.onFinishAppointmentClicked(checkMeasureAppointment!)}
          />
        ),
      });
    } else if (job?.stage === 'Measure Finished') {
      speedDialItems.push({
        template: (
          <TwoSpeedDialItem icon={faFileArrowUp} label="Release DCM" onClick={() => this.onReleaseDcmClick()} />
        ),
      });
    }
    return speedDialItems;
  }

  getCurrentAppointment(job?: Job): Appointment | undefined {
    return job?.appointments?.find(
      appointment => appointment.stage !== 'Cancelled' && appointment.stage !== 'Completed'
    );
  }

  render() {
    const {
      loading,
      job,
      showBookAppointmentDialog,
      showStartAppointmentDialog,
      savingAppointment,
      startDialogLoading,
      showOpenDcmDialog,
    } = this.state;

    const onGDriveClick = (url: string) => {
      window.open(`${url}`, '_empty');
    };

    if (loading) {
      return (
        <div className="p-d-flex p-ai-center w-100 h-100">
          <ProgressSpinner />
        </div>
      );
    }

    if (!job) {
      return <></>;
    }

    const speedDialItems: MenuItem[] = this.getSpeedDialItems();
    const currentAppointment = this.getCurrentAppointment(job);

    return (
      <div id="job_detail_page_container" className="p-mt-1">
        <div id="job_detail_page" className="app-page">
          <ScrollPanel className="drafts-scroll-panel">
            <JobDetailContent job={job} />
            <AppointmentContent
              job={job}
              currentAppointment={currentAppointment}
              savingAppointment={savingAppointment}
              onShowAppointmentDialog={this.onBookNowClicked}
              onBookClick={this.onBookClick}
              onStartAppointmentClicked={() => this.setState({showStartAppointmentDialog: true})}
              onFinishAppointmentClicked={this.onFinishAppointmentClicked}
            />

            <ContactsContent job={job} />
            {!!job.installation_info && <InstallationInfoContent installationInfo={job.installation_info} />}
            {(job.external_refs?.gdrive || job.external_refs?.base) && (
              <div className="p-grid p-mb-4">
                <label className="p-col-2">links</label>
                {job.folder && (
                  <Button
                    className="p-button-link p-button-info p-p-0 p-mx-2 job-link"
                    label={'gDrive'}
                    onClick={() =>
                      onGDriveClick(`https://drive.google.com/drive/folders/${job?.external_refs!.gdrive}`)
                    }
                  />
                )}
                {job.external_refs?.base && (
                  <Button
                    className="p-button-link p-button-info p-p-0 p-mx-2 job-link"
                    label={'Base'}
                    onClick={() =>
                      onGDriveClick(`https://app.futuresimple.com/sales/deals/${job?.external_refs!.base}`)
                    }
                  />
                )}
              </div>
            )}

            <DocumentsContent
              jobStage={job.stage}
              onOriginalClick={this.onOriginalRequestClick}
              onDcmClick={this.onDcmClick}
            />
          </ScrollPanel>
          <BookAppointmentDialog
            job={job}
            onBook={this.onBookAppointment}
            onCancel={this.onCancelAppointment}
            showDialog={showBookAppointmentDialog}
            saving={savingAppointment}
            toast={this.toast}
          />
          <StartAppointmentDialog
            showDialog={showStartAppointmentDialog}
            onNo={this.onStartDialogNoClick}
            onYes={this.onStartDialogYesClick}
            loading={startDialogLoading}
          />
          <OpenDcmDialog
            showDialog={showOpenDcmDialog}
            onReadOnly={this.onDcmOpen}
            onStartAndEdit={this.onStartDialogYesClick}
            onCancel={this.onOpenDcmDialogCancel}
            loading={startDialogLoading}
          />
          <TwoSpeedDial model={speedDialItems} />
        </div>
        <Toast ref={this.toast} />
      </div>
    );
  }
}

export default withRouter(JobDetail);
