import React from 'react';
import {Job} from 'two-core';
import config from '../../../config/config';

interface Props {
  job: Job;
}

export const JobDetailContent = (props: Props) => {
  const {job} = props;
  const services = job.requested_services;
  const address = `${job.address.street}, ${job.address.suburb}, ${job.address.postCode}, ${job.address.country}`;
  const currentCompanyId = localStorage.getItem(config().ls_keys.current_company);
  let fitter = '';
  if (job.fitting_provider_id === currentCompanyId) {
    fitter = job.lead_fitter?.label ?? '';
  } else {
    const fittingProvider = job.fitting_provider;
    fitter = `${fittingProvider?.account_number} (${fittingProvider?.trading_as ?? fittingProvider?.name})`;
  }

  return (
    <div className="p-my-4">
      <div className="p-field p-grid p-mb-1">
        <label className="p-col-2">{services && <small>services</small>}</label>
        <div className="p-col-6">{services}</div>
        <label className="p-col-1">
          <small>stage</small>
        </label>
        <div className="p-col-3">{job.stage}</div>
      </div>
      <div className="p-field p-grid p-mb-1">
        <label className="p-col-2 p-as-start">
          <small>address</small>
        </label>
        <div className="p-col-10">{address}</div>
      </div>
      {fitter !== '' && (
        <div className="p-field p-grid p-mb-1">
          <label className="p-col-2 p-as-start">
            <small>fitter</small>
          </label>
          <div className="p-col-10">{fitter}</div>
        </div>
      )}
    </div>
  );
};
