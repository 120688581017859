import React from 'react';
import {InstallationInfo} from 'two-core';

interface Props {
  installationInfo: InstallationInfo;
}

export const InstallationInfoContent = ({installationInfo}: Props) => {
  return (
    <div className="p-mb-4">
      <div className="p-field p-grid p-mb-1">
        <label className="p-col-2">
          <small>duration</small>
        </label>
        <div className="p-col-2">
          {installationInfo?.time_required} {installationInfo?.time_required === 1 ? 'hour' : 'hours'}
        </div>
        <label className="p-col-2">
          <small># fitters</small>
        </label>
        <div className="p-col-2">{installationInfo?.fitters_required}</div>
        {/*<label className="p-col-2">
          <small># helpers</small>
        </label>
        <div className="p-col-2">{installationInfo?.offsiders_required}</div>*/}
      </div>
      {!!installationInfo.fixings_required?.length && (
        <div className="p-field p-grid p-mb-1">
          <label className="p-col-2">
            <small>fixing</small>
          </label>
          <div className="p-col-10">{installationInfo.fixings_required.join(', ')}</div>
        </div>
      )}
      {!!installationInfo.additional_items?.length && (
        <div className="p-field p-grid p-mb-1">
          <label className="p-col-2">
            <small>tools</small>
          </label>
          <div className="p-col-10">{installationInfo?.additional_items?.join(', ')}</div>
        </div>
      )}
    </div>
  );
};
