import React from 'react';
import {Button} from 'primereact/button';
import {TwoDialog} from 'two-app-ui';

interface Props {
  showDialog: boolean;
  onReadOnly: () => void;
  onStartAndEdit: () => void;
  onCancel: () => void;
  loading: boolean;
}

export const OpenDcmDialog = ({showDialog, onReadOnly, onStartAndEdit, loading, onCancel}: Props) => {
  const footer = (
    <div className={'p-d-flex p-justify-end'}>
      <Button label="Cancel" className={'p-mr-2 p-button-text'} onClick={onCancel} disabled={loading} />
      <Button label="Read-Only" className={'p-mr-2 p-button-text'} onClick={onReadOnly} disabled={loading} />
      <Button label="Start And Edit" onClick={onStartAndEdit} loading={loading} />
    </div>
  );
  return (
    <TwoDialog
      header={'Open DCM'}
      showDialog={showDialog}
      style={{width: '50vw'}}
      breakpoints={{'768px': '80vw', '576px': '95vw'}}
      onHide={() => {}}
      loading={false}
      footer={footer}
    >
      <div className="p-d-flex w-100">
        <span>
          The DMC is locked in the current stage. Do you wish to open it in read-only mode, or do you want me to start
          the appointment and open the DCM for edit?
        </span>
      </div>
    </TwoDialog>
  );
};
