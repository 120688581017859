import React from 'react';
import AppFrame from './components/AppFrame/AppFrame';
import Made2FitAppContext from './context/Made2FitAppContext';

import {AppContext, AuthService, ToastService, UsersService} from 'two-app-ui';
import JobsService from './services/JobsService';
import AppointmentsService from './services/AppointmentsService';
import JobDocumentsService from './services/JobDocumentsService';
import ProductDefinitionsService from './services/ProductDefinitionsService';
import TleService from './services/TleService';
import CompaniesService from './services/CompaniesService';
import ContactsService from './services/ContactsService';

const authService = new AuthService();
const toastService = new ToastService();
const usersService = new UsersService(authService);
const jobsService = new JobsService(authService);
const appointmentsService = new AppointmentsService(authService);
const jobDocumentsService = new JobDocumentsService(authService);
const productDefinitionsService = new ProductDefinitionsService(authService);
const tleService = new TleService(authService);
const companiesService = new CompaniesService(authService);
const contactsService = new ContactsService(authService);

function App() {
  return (
    <AppContext.Provider
      value={{
        authService: authService,
        usersService: usersService,
        toastService: toastService,
      }}
    >
      <Made2FitAppContext.Provider
        value={{
          authService: authService,
          usersService: usersService,
          toastService: toastService,
          jobsService: jobsService,
          appointmentsService: appointmentsService,
          jobDocumentsService: jobDocumentsService,
          productDefinitionsService: productDefinitionsService,
          tleService: tleService,
          companiesService: companiesService,
          contactsService: contactsService,
        }}
      >
        <AppFrame />
      </Made2FitAppContext.Provider>
    </AppContext.Provider>
  );
}

export default App;
