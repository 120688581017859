import React, {useContext} from 'react';
import {Appointment, Job} from 'two-core';
import {Button} from 'primereact/button';
import {DateTime} from 'luxon';
import Made2FitAppContext from '../../../context/Made2FitAppContext';
import {getTwoDateFormat} from 'two-app-ui';

interface Props {
  job: Job;
  savingAppointment: boolean;
  onStartAppointmentClicked: (appointment: Appointment) => void;
  onFinishAppointmentClicked: (appointment: Appointment) => void;
  onShowAppointmentDialog: (appointment?: Appointment) => void;
  onBookClick: (appointment: Appointment) => void;
  currentAppointment?: Appointment;
}

export const AppointmentContent = (props: Props) => {
  const {job, currentAppointment, onShowAppointmentDialog, onStartAppointmentClicked, onFinishAppointmentClicked} =
    props;
  const context = useContext(Made2FitAppContext);
  const dateFormat = context.usersService?.settings?.date_format;

  const startPlan = currentAppointment?.start_plan
    ? DateTime.fromISO(currentAppointment.start_plan.toString())
        .toFormat(getTwoDateFormat(dateFormat, 'dateTimeWithoutYear'))
        .toLowerCase()
    : '';
  const endPlan = currentAppointment?.end_plan
    ? DateTime.fromISO(currentAppointment.end_plan.toString())
        .toFormat(getTwoDateFormat(dateFormat, 'timeOnly'))
        .toLowerCase()
    : '';
  const startReal = currentAppointment?.start_real
    ? DateTime.fromISO(currentAppointment.start_real.toString())
        .toFormat(getTwoDateFormat(dateFormat, 'dateTimeWithoutYear'))
        .toLowerCase()
    : '';
  const endReal = currentAppointment?.end_real
    ? DateTime.fromISO(currentAppointment.end_real.toString())
        .toFormat(getTwoDateFormat(dateFormat, 'timeOnly'))
        .toLowerCase()
    : '';

  let planFieldContent;
  let realFieldContent;
  if (job.stage === 'New' || job.stage === 'Assigned') {
    planFieldContent = (
      <>
        <div>no plan yet</div>
        <Button
          className="p-button-link p-p-0 p-ml-2 p-mb-1 job-link"
          label="plan or book now"
          onClick={() => onShowAppointmentDialog()}
        />
      </>
    );
  } else if (currentAppointment) {
    if (currentAppointment.stage === 'Planned') {
      planFieldContent = (
        <>
          <div>
            {startPlan} - {endPlan} (plan only)
          </div>
          <Button
            className="p-button-link p-p-0 p-ml-2 p-mb-1 job-link"
            label="book"
            onClick={() => onShowAppointmentDialog(currentAppointment)}
          />
        </>
      );
    }
    if (
      currentAppointment.stage === 'Booked' ||
      currentAppointment.stage === 'Started' ||
      currentAppointment.stage === 'Finished'
    ) {
      planFieldContent = (
        <>
          <div>
            {startPlan} - {endPlan}
          </div>
          {currentAppointment.stage === 'Booked' && (
            <Button
              className="p-button-link p-p-0 p-ml-2 p-mb-1 job-link"
              label="re-book"
              onClick={() => onShowAppointmentDialog(currentAppointment)}
            />
          )}
        </>
      );
      realFieldContent = (
        <>
          {startReal && (
            <div className="p-mr-2">
              {startReal}
              {endReal && ` - ${endReal}`}
            </div>
          )}
          {currentAppointment.stage === 'Booked' && (
            <Button
              className="p-button-link p-p-0 p-mb-1 job-link"
              label="start"
              onClick={() => onStartAppointmentClicked(currentAppointment)}
            />
          )}
          {currentAppointment.stage === 'Started' && (
            <Button
              className="p-button-link p-p-0 p-mb-1 job-link"
              label="finish"
              onClick={() => onFinishAppointmentClicked(currentAppointment)}
            />
          )}
        </>
      );
    }
  }

  if (currentAppointment) {
    return (
      <div className="p-mb-4">
        <div className="p-field p-grid p-mb-1">
          <label className="p-col-2">
            <small>visit</small>
          </label>
          <div className="p-col-10 p-d-flex">{`${currentAppointment.type} (${currentAppointment.stage})`}</div>
        </div>
        <div className="p-field p-grid p-mb-1">
          <label className="p-col-2">
            <small>plan</small>
          </label>
          <div className="p-col-10 p-d-flex">{planFieldContent}</div>
        </div>
        {realFieldContent && (
          <div className="p-field p-grid p-mb-1">
            <label className="p-col-2">
              <small>real</small>
            </label>
            <div className="p-col-10 p-d-flex">{realFieldContent}</div>
          </div>
        )}
        {currentAppointment?.note && (
          <div className="p-field p-grid p-mb-1 p-mt-4">
            <label className="p-col-2">
              <small>note</small>
            </label>
            <div className="p-col-10 p-d-flex">{currentAppointment.note}</div>
          </div>
        )}
      </div>
    );
  }

  return <></>;
};
