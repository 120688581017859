import React, {useCallback, useContext} from 'react';
import {Card} from 'primereact/card';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {Job} from 'two-core';
import {DateTime} from 'luxon';
import './JobCard.scss';
import {faScrewdriverWrench, faUser, faBlockBrick, faUserHelmetSafety} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Made2FitAppContext from '../../context/Made2FitAppContext';
import {getTwoDateFormat} from 'two-app-ui';

interface Props {
  job: Job;
}

export const JobCard = withRouter((props: RouteComponentProps & Props) => {
  const {job, history} = props;
  const context = useContext(Made2FitAppContext);
  const dateFormat = context.usersService?.settings?.date_format;
  const showNewJobAge = context.usersService?.settings?.ux?.made2fit_oba?.schedule?.age;

  const onDraftClick = useCallback(() => {
    history.push(`/job/${job.id}`);
  }, []);

  let jobTypeLabel = `${job.requested_services}`;
  let topTime = '';
  let bottomTime = '';
  const ageTimer = ['New', 'Assigned', 'Measure Review', 'Dealer Approval Pending'].includes(job.stage);
  if (ageTimer && showNewJobAge) {
    const createdAt: DateTime = DateTime.fromJSDate(new Date(job.created_at));
    const createdDuration = DateTime.now().diff(createdAt, ['days', 'hours']);
    topTime = `${createdDuration.toObject().days}d`;
  } else {
    const nextApp = job.appointments?.find(app => app.stage !== 'Completed' && app.stage !== 'Cancelled');
    if (nextApp && nextApp.start_plan) {
      jobTypeLabel = `${nextApp.type}`;
      topTime = DateTime.fromISO(nextApp.start_plan.toString())
        .toFormat(getTwoDateFormat(dateFormat, 'timeOnly'))
        .toLowerCase();
      if (nextApp.end_plan) {
        bottomTime = DateTime.fromISO(nextApp.end_plan.toString())
          .toFormat(getTwoDateFormat(dateFormat, 'timeOnly'))
          .toLowerCase();
      }
    }
  }

  const ii = job.installation_info;
  let jobDesc = <></>;
  if (ii) {
    let timeRequired = <></>;
    if (ii.time_required) {
      timeRequired = <span className="p-mx-2">{ii.time_required}h</span>;
    }
    let fitters = <></>;
    if (ii.fitters_required) {
      fitters = (
        <span className="p-mx-2">
          {ii.fitters_required}
          <FontAwesomeIcon icon={faUserHelmetSafety} />
        </span>
      );
    }
    let helpers = <></>;
    if (ii.offsiders_required) {
      helpers = (
        <span className="p-mx-2">
          {ii.offsiders_required}
          <FontAwesomeIcon icon={faUser} />
        </span>
      );
    }
    let extras = <></>;
    if (ii.additional_items && ii.additional_items.length > 0) {
      extras = (
        <span className="p-mx-2">
          {ii.additional_items.length}
          <FontAwesomeIcon icon={faScrewdriverWrench} />
        </span>
      );
    }
    let fixings = <></>;
    if (ii.fixings_required && ii.fixings_required.length > 1) {
      fixings = (
        <span className="p-mx-2">
          {ii.fixings_required.length}
          <FontAwesomeIcon icon={faBlockBrick} />
        </span>
      );
    }
    jobDesc = (
      <div className="p-d-flex p-jc-center">
        {timeRequired} {fitters} {/*{helpers}*/} {extras} {fixings}
      </div>
    );
  }

  return (
    <div onClick={onDraftClick}>
      <Card className="job-card">
        <div className="p-p-2 p-my-2 p-grid">
          <div className="p-d-flex p-flex-row p-col-12 p-mt-2 p-px-2 p-py-0">
            <div className="p-text-center p-col-2 p-p-0" style={{fontSize: '1.25rem'}}>
              {topTime}
            </div>
            <div className="p-col-6 p-p-0" style={{fontSize: '1.25rem'}}>
              {job.title}
            </div>
            <div className="p-text-right p-col-4 p-pt-0 p-pb-2 p-px-2">{job.owner?.name}</div>
          </div>
          <div className="p-d-flex p-flex-row p-col-12 p-mb-2 p-px-2 p-py-0">
            <div className="p-text-center p-col-2 p-p-0">{bottomTime}</div>
            <div className="p-col-4 p-p-0">{jobTypeLabel}</div>
            <div className="p-col-3 p-py-0 p-d-grid p-d-justify-content-center">{jobDesc}</div>
            <div className="p-col-3 p-text-right p-py-0 p-px-2">{job.address.suburb}</div>
          </div>
        </div>
      </Card>
    </div>
  );
});
