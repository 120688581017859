import config from '../config/config';
import {AuthService, ApiService} from 'two-app-ui';
import {ApiListResponse, ApiResponse} from 'two-core';

class ProductDefinitionsService extends ApiService {
  constructor(aus: AuthService) {
    super(aus);
  }

  async getProductDefinitions(prodDefRevisionId: number, companyId?: string): Promise<ApiListResponse> {
    if (!companyId) {
      companyId = localStorage.getItem(config().ls_keys.current_company)!;
    }
    return this.get(config(companyId).endpoints.productDefinitions + '/' + prodDefRevisionId)
      .then((data: ApiResponse | ApiListResponse) => {
        return Promise.resolve(data as ApiListResponse);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }
}

export default ProductDefinitionsService;
